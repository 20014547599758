import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IHelpDocumentsIndex} from './help-documents-index.interface';
import {Observable} from 'rxjs';
import {map, shareReplay, take} from 'rxjs/operators';
import {IHelpDocument} from './help-document.interface';

@Injectable({providedIn: 'root'})
export class RtfmService {

	/**
	 * Path assoluto di puntamento agli asset dei documenti
	 */
	public static readonly docsRoot = '/assets/docs';
	private static readonly docsIndex = `${RtfmService.docsRoot}/index.json`;

	private _documents$: Observable<IHelpDocumentsIndex> = null;
	/**
	 * Elenco completo dei documenti disponibili
	 */
	public get documents$(): Observable<IHelpDocumentsIndex> {
		if (!this._documents$) {
			this._documents$ = this
				.http
				.get(RtfmService.docsIndex)
				.pipe(
					map(res => res as IHelpDocumentsIndex),
					shareReplay(1)
				);
		}
		return this._documents$;
	}

	constructor(private http: HttpClient) {
	}

	/**
	 * Ottiene un singolo documento in base al codice
	 * @param code codice del documento
	 */
	public getByCode(code: string): Observable<IHelpDocument> {
		return this
			.documents$
			.pipe(
				map(index => index.documenti.find(d => d.codice === code)),
				take(1)
			);
	}
}
