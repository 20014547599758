import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators} from '@angular/forms';

@Directive({
	selector: '[nr-email][ngModel]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: NrEmailDirective,
			multi: true
		}
	]
})

export class NrEmailDirective implements Validator {

	registerOnValidatorChange(fn: () => void): void {
	}

	validate(control: AbstractControl): ValidationErrors | null {
		return Validators.email(control);
	}
}
