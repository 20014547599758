<!-- <section class="intro-top">&nbsp;</section> -->
<div class="container">
	<section class="intro-single">
		<div class="row no-gutter">
			<div class="col-md-12 col-lg-9">
				<div class="title-single-box">
					<h1 class="title-single">{{ pageHeaderName() }}</h1>
					<span class="color-text-a"
						[innerHtml]="pageHeaderContent()"></span>
				</div>
			</div>
			<div class="col-md-12 col-lg-3">
				<nr-destinations-list [destinations]="destinations"></nr-destinations-list>
			</div>
		</div>
	</section>

	<section class="card-list">
		<div class="row row-cols-1 row-cols-md-3">
			<div class="col mb-4"
				*ngFor="let destination of destinations">
				<nr-destination-card [destination]="destination"></nr-destination-card>
			</div>
		</div>
	</section>

	<ng-container *ngIf="pageData">
		<div class="row">
			<div class="col-md-12">
				<ng-container *ngFor="let item of pageDataBody">
					<ng-container [ngSwitch]="item.entityType">
						<ng-container *ngSwitchCase="eTipoEntita.Articolo">
							<article>
								<!-- <div class="title-box-d">
								<h3 class="title-d" [innerHtml]="item.name"></h3>
							</div> -->
								<div class="text-description"
									[innerHtml]="item.content"></div>
							</article>
						</ng-container>
						<ng-container *ngSwitchCase="eTipoEntita.Immagine">
							<img class="img-fluid"
								[src]="item.content"
								[alt]="item.name">
						</ng-container>
						<!-- <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultTemplate"></ng-container> -->
					</ng-container>
				</ng-container>
			</div>
		</div>
	</ng-container>
</div>
