import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from 'nr-common';
import {Slide} from '../../shared/models/slide';
import {LoginUserData} from '../../shared/models/loginData';
import {environment} from 'projects/nr-b2c/src/environments/environment';
import {UrlService} from '../../services/url.service';

@Component({
	selector: 'nr-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	@Input() slides: Slide[] = [];
	mainSlide: Slide;
	public loginUserData: LoginUserData;
	public submitted = false;
	public working = false;

	@ViewChild('frmLogin', {static: true})
	protected form: NgForm;

	constructor(
		private user: UserService,
		private translate: TranslateService,
		private route: ActivatedRoute,
		private router: Router,
		private url: UrlService
	) {
	}

	ngOnInit(): void {
		this.mainSlide = this.getRandomSlide();
		this.loginUserData = new LoginUserData();
	}

	public async doLogin() {
		this.submitted = true;
		if (this.form.valid) {
			this.working = true;
			try {
				this.form.control.disable();
				const user = await this.user.login(this.loginUserData.username, this.loginUserData.password);
				this.working = false;
				if (user) {
					if (this.route.snapshot.queryParamMap.has('returnUrl')) {
						await this.router.navigate([this.route.snapshot.queryParamMap.get('returnUrl')]);
					} else {
                        window.location.href = this.url.getIntranetAdvHomeUrlFromUser(user);
					}
				} else {
					this.loginUserData.password = null;
					this.submitted = false;
				}
			} catch (e) {
				console.error(e);
			} finally {
				this.working = false;
				this.form.control.enable();
			}
		}
	}

	public doRegister() {
		location.href = environment.b2bIntranetUsrSignIn;
	}

	public doRecover() {
		location.href = environment.b2bIntranetPwdRecover;
	}

	getRandomSlide(): Slide {
		if (this.slides.length === 0) return new Slide('./assets/img/logoNaar.png');
		const randomIndex = Math.floor(Math.random() * this.slides.length);
		return this.slides[randomIndex];
	}
}
