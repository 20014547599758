import {
	Component,
	OnInit
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { WebsiteService } from '../../services/website.service';
import { BasePageComponent } from '../../shared/components/base-page/base-page.component';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ETipoEntita } from 'nr-common';

@Component({
  selector: 'nr-contactspage',
  templateUrl: './contactspage.component.html',
  styleUrls: ['./contactspage.component.scss']
})
export class ContactspageComponent extends BasePageComponent implements OnInit {
	// public privacyText = "Testo di prova privacy";

	constructor(
		apiService: ApiService,
		webSiteService: WebsiteService,
		titleService: Title,
		translateService: TranslateService
	) {
		super(apiService, webSiteService, titleService, translateService);
		this.pageName = 'contacts';
		// this.pageLinkId = this.webSiteService.CMS_PAGELINK_ID['contacts'];	// 35 è il CMS-Id della pagina Idee del NAAR-B2c
	}

	ngOnInit(): void {
		super.ngOnInit();
		// this.privacyText = this.getPrivacyText();
	}

	getPrivacyText(): string {
		return '';
		// let result = this.pageData?.elencoDati?.slice(-1)[0].content;
		// // this.cutThePageDataBodyTail();
		// return result;
	}

}
