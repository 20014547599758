import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class StorageService {

	public static readonly knownKeys = {
		/**
		 * Elenco dei viaggi recentemente utilzzati
		 */
		recentTrips: 'recentTrips',

		/**
		 * Settaggi della console
		 */
		consoleSettings: 'consoleSettings',

		/**
		 * Storico della console
		 */
		consoleHistory: 'consoleHistory'
	};

	private storage: Storage;

	constructor() {
		this.storage = window.localStorage;
	}

	public setValue(key: string, value: any) {
		const typeOfValue = typeof (value);
		switch (typeOfValue) {
			case 'boolean':
				this.storage.setItem(key, `b:${value}`);
				break;
			case 'bigint':
				this.storage.setItem(key, `i:${value}`);
				break;
			case 'number':
				this.storage.setItem(key, `n:${value}`);
				break;
			case 'string':
				this.storage.setItem(key, `s:${value}`);
				break;
			case 'object':
				const valueStr = JSON.stringify(value);
				this.storage.setItem(key, `o:${valueStr}`);
				break;
			default:
				throw new Error(`Unsupported value type ${typeOfValue}`);
		}
	}

	public getValue(key: string, defaultValue: any = null): any {
		const storedValue = this.storage.getItem(key);
		if (storedValue === null) {
			return defaultValue;
		}
		const typeOfValue = storedValue.substring(0, 1);
		const value = storedValue.substring(2);
		switch (typeOfValue) {
			case 'b':
				return value === 'true';
			case 'i':
				return parseInt(value, 10);
			case 'n':
				return Number(value);
			case 's':
				return value;
			case 'o':
				return JSON.parse(value);
		}
		return defaultValue;
	}
}
