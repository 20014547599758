import {
	Component,
	Inject,
	OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import {
	B2cArticle,
	OpenDestination
} from 'nr-common';

import { ApiService } from '../../services/api.service';
import { DestinationService } from '../../services/destination.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'nr-opendestpage-detail',
	templateUrl: './opendestpage-detail.component.html',
	styleUrls: ['./opendestpage-detail.component.scss']
})
export class OpendestpageDetailComponent implements OnInit {
	public article: B2cArticle;
	//public openDestinations: OpenDestination[];
	public openDestination: OpenDestination;

	constructor(
		private apiService: ApiService,
		private activeRoute: ActivatedRoute,
		private destinationService: DestinationService,
		public translateService: TranslateService,
        private google: GoogleAnalyticsService,
        private title: Title,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
	) { }

	ngOnInit(): void {
		this.activeRoute
			.params
			.subscribe(routeParams => {
				this.apiService
					.getArticle(routeParams.id)
					.subscribe((data) => {
						this.article = data;

                        // OM 09.06.2023: setting the title of the page
                        this.title.setTitle(data.title ? "Naar Tour Operator - " + data.title : "Naar Tour Operator");

                        // OM 09.06.2023: Google Analytics
                        this.google.tag(data.title,
                                        this.router.url,
                                        this.document.location.href);

					});
				this.apiService
					.getOpenDestinations()
					.subscribe((data) => {
						this.openDestination = data.find(f => f.destinazione?.luogoID == routeParams.ido);	// this.openDestinations[this.article.luogoId];
						//this.openDestination = data.find(f => f.destinazione?.luogoID == this.article.luogoId);	// this.openDestinations[this.article.luogoId];
					});
				//this.openDestination = this.destinationService.getOpenDestination(this.article.luogoId);
			});
	}

}
