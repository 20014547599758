/* tslint:disable:variable-name */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {BackendService, BaseDataService, GetIdeaResponse, GetIdeasResponse, Idea, IdeaInfo, IGetIdeaRequest, ISiteRequest} from 'nr-common';
import {WebsiteService} from './website.service';

/**
 * SERVIZIO PER LA GESTIONE DELLE IDEE DI VIAGGIO
 * --------------------------------------------------------------
 * Per abbattere il numero di chiamate al server.
 * Mantiene in cache locale:
 *	- l'insieme delle ideas
 *	- dettagli delle singole idee in lazy load
 */
const SINGLE_ENDPOINT = 'b2c/idea';
const PLURAL_ENDPOINT = 'b2c/ideas';

@Injectable({
	providedIn: 'root'
})
export class IdeaService {
	private cache_ideas$: Observable<Idea[]> = null;
	private cache_ideaInfo$: Observable<IdeaInfo>[] = [];
	private siteRequest: ISiteRequest;	// SiteRequest usati per chiamate server

	constructor(
		private backend: BackendService,
		public website: WebsiteService,
		private baseData: BaseDataService
	) {
		this.siteRequest = {
			siteID: this.website.currentWebsite.id,
			lingua: this.baseData.getLingua()
		};
	}

	/**
	 * Recupera i dati delle destinazioni
	 * @returns Observable<idea[]>
	 */
	public getIdeas(): Observable<Idea[]> {
		if (this.cache_ideas$ == null) {
			try {
				this.cache_ideas$ = this.backend
					.call(PLURAL_ENDPOINT, this.siteRequest)
					.pipe(
						map((data: GetIdeasResponse) => {
							return data.idee;
						}),
						shareReplay(1)
					);
			} catch (error) {
				console.error(error);
			}
		}
		return this.cache_ideas$;
	}

	public getIdeaInfo(ideaId: number): Observable<IdeaInfo> {
		if (!this.cache_ideaInfo$[ideaId]) {
			try {
				const localRequest: IGetIdeaRequest = {
					siteID: this.website.currentWebsite.id,
					lingua: this.baseData.getLingua(),
					ideaID: ideaId
				};

				this.cache_ideaInfo$[ideaId] = this
					.backend
					.call(SINGLE_ENDPOINT, localRequest)
					.pipe(
						map((data: GetIdeaResponse) => {
							return data.idea;
						}),
						shareReplay(1)
					);
			} catch (error) {
				console.error(error);
			}
		}
		return this.cache_ideaInfo$[ideaId];
	}
}
