import { Component, Input } from '@angular/core';
import { GMapPoint } from 'nr-common';

enum eStrokeColor {
	DRIVING = 'red',
	TRANSIT = 'green',
}

@Component({
	selector: 'nr-maps nr-maps-route',		//if there are results show the detailed direction otherwise show a straight line from origin to destination
	template: `
				<ng-container *ngIf="result; else zeroResult">	
					<nr-maps-polyline 
					[strokeColor]="strokeColor">
					<nr-maps-polyline-point  *ngFor="let coordinate of polyline?.getPath().getArray()" [latitude]="coordinate.lat()" [longitude]="coordinate.lng()"> </nr-maps-polyline-point>
				</nr-maps-polyline>
			</ng-container>
			<ng-template #zeroResult>
				<nr-maps-polyline 
				[strokeColor]="strokeColor">
				<nr-maps-polyline-point  [latitude]="origin.lat" [longitude]="origin.lng"> </nr-maps-polyline-point>
				<nr-maps-polyline-point   [latitude]="destination.lat" [longitude]="destination.lng"> </nr-maps-polyline-point>
			</nr-maps-polyline>
			</ng-template>
    `,
})
export class NrMapsRoute {
	//*private triedOnce = false;  //If trains don't work try driving

	@Input() origin: GMapPoint;

	@Input() destination: GMapPoint;

	@Input() travelMode?: google.maps.TravelMode;

	@Input() transitOptions?: google.maps.TransitOptions;

	@Input() drivingOptions?: google.maps.DrivingOptions;

	@Input() waypoints: google.maps.DirectionsWaypoint[] = [];

	@Input() optimizeWaypoints = true;

	@Input() provideRouteAlternatives = false;

	@Input() avoidHighways = false;

	@Input() avoidTolls = false;

	@Input() avoidFerries = false;

	@Input() unitSystem?: google.maps.UnitSystem;

	@Input() strokeColor: string;


	public result: boolean = true;
	public polyline: google.maps.Polyline;
	private directionsService: google.maps.DirectionsService;
	private readonly MAX_WAYPOINTS: number = 25;

	constructor() {
	}


	ngOnInit() {
		google.maps.importLibrary("routes").then(() => {

			if (!this.directionsService) {
				this.directionsService = new google.maps.DirectionsService();
			}
			this.directionDraw();
		});
	}



	private directionDraw() {
		this.strokeColor = this.strokeColor || (eStrokeColor[this.travelMode] || eStrokeColor.TRANSIT);
		if (this.waypoints != null && this.waypoints.length >= this.MAX_WAYPOINTS) {
			this.waypoints = [...this.waypoints.slice(0, this.MAX_WAYPOINTS - 1), this.waypoints[this.waypoints.length - 1]];
			//console.warn("Max waypoints exceed. New waypoints list:", this.waypoints)
		}
		this.directionsService.route({
			origin: { lat: this.origin.lat, lng: this.origin.lng },
			destination: { lat: this.destination.lat, lng: this.destination.lng },
			travelMode: this.travelMode || google.maps.TravelMode.DRIVING,
			transitOptions: {
				modes:
					[google.maps.TransitMode.TRAIN,
					google.maps.TransitMode.RAIL,
					google.maps.TransitMode.SUBWAY,
					],
				routingPreference: google.maps.TransitRoutePreference.FEWER_TRANSFERS
			},
			drivingOptions: this.drivingOptions,
			waypoints: this.waypoints,
			optimizeWaypoints: this.optimizeWaypoints,
			provideRouteAlternatives: this.provideRouteAlternatives,
			avoidHighways: this.avoidHighways,
			avoidTolls: this.avoidTolls,
			avoidFerries: this.avoidFerries,
			unitSystem: this.unitSystem,
		}, (response, status) => {
			switch (status) {
				case google.maps.DirectionsStatus.OK:
					const route = response.routes[0];
					const path = route.overview_path
					this.polyline = new google.maps.Polyline({
						path: path,
						geodesic: true
					});
					break;
				case google.maps.DirectionsStatus.OVER_QUERY_LIMIT:
					console.warn('The webpage has sent too many requests within the allowed time period.');
					this.result = false;
					break;
				case google.maps.DirectionsStatus.ZERO_RESULTS:
					console.warn("No data routes available");
					this.result = false;
					/*				//*if trains don't work try driving
					console.warn("No data routes available, trying to convert graphically train path to driving path");
					 if (!this.triedOnce) { //If trains don't work try driving
				this.triedOnce = true;
				this.travelMode = null
				this.directionDraw(); 
				}		*/
					break;
				default:
					console.warn(status);
					break;
			}
		});
	}
}
