import {Directive, HostBinding, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {NrSortDirection, rotate} from './nr-sort-direction.type';
import {TableSource} from './table-source.class';

@Directive({
	selector: '[sort]'
})
export class TableSourceSortDirective implements OnInit, OnChanges, OnDestroy {

	@Input() sort: string;
	private direction: NrSortDirection = '';

	@HostBinding('class.asc') ascClass = false;
	@HostBinding('class.desc') descClass = false;
	@HostBinding('class.unsorted') unsortedClass = true;

	private s1: Subscription;

	constructor(private tableSource: TableSource) {
	}

	@HostListener('click', ['$event'])
	rotate() {
		this.direction = rotate[this.direction];
		this.ascClass = this.direction === 'asc';
		this.descClass = this.direction === 'desc';
		this.unsortedClass = this.direction === '';
		this.tableSource.setSorting({
			direction: this.direction,
			column: this.sort
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		// console.log('[ngOnChanges] changes.sort=', changes.sort);
		// console.log('[ngOnChanges] changes.sort.firstChange=', changes.sort.firstChange);
		if (changes.sort && !changes.sort.firstChange) {
			this.ngOnDestroy();
			this.ngOnInit();
		}
	}

	ngOnDestroy(): void {
		this.s1?.unsubscribe();
	}

	ngOnInit(): void {
		this.s1 = this.tableSource.sortChanged$.subscribe(sort => {
			// console.log('[sortChanged$] this.sort', this.sort);
			// console.log('[sortChanged$] sort', sort);
			if (sort.column === this.sort) {
				this.unsortedClass = false;
				this.ascClass = sort.direction === 'asc';
				this.descClass = sort.direction === 'desc';
				this.direction = sort.direction;
			} else {
				this.ascClass = false;
				this.descClass = false;
				this.unsortedClass = true;
				this.direction = '';
			}
		});

		// Impostazione iniziale
		if (this.tableSource.sorting.column === this.sort) {
			this.unsortedClass = false;
			this.ascClass = this.tableSource.sorting.direction === 'asc';
			this.descClass = this.tableSource.sorting.direction === 'desc';
			this.direction = this.tableSource.sorting.direction;
		}
	}

}
