import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {UserService} from './user.service';


@Injectable({providedIn: 'root'})
export class IntranetUserGuard implements CanActivate {
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (!(this.user.isLoggedIn && this.user.can(['intranet']))) {
			this.router.navigate(['/auth/login'], {
				queryParams: {
					returnUrl: state.url
				}
			});
		}
		return this.user.isLoggedIn && this.user.can(['intranet']);
	}

	constructor(private router: Router, private user: UserService) {
	}
}
