import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Idea, IdeaInfo} from 'nr-common';
import {ApiService} from '../../services/api.service';
import {IdeaService} from '../../services/idea.service';
import {UrlService} from '../../services/url.service';
import {TripCard} from '../../shared/models/trip-card';

const MAX_NUM_OF_BIG_TRIP = 2;	// NUmero massimo di Viaggi mostrati "grandi" nella tripCard gallery

@Component({
	selector: 'nr-ideapage',
	templateUrl: './ideapage.component.html',
	styleUrls: ['./ideapage.component.scss']
})
export class IdeapageComponent implements OnInit {
	public ideas: Idea[];
	public ideaInfo: IdeaInfo;
	tripCards: TripCard[] = [];

	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: '<div class="nav-btn next-slide"><i class="fa fa-chevron-right"></i></div>',
		prevArrow: '<div class="nav-btn prev-slide"><i class="fa fa-chevron-left"></i></div>',
		dots: true,
		infinite: true,
		swipe: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	slides = [];


	constructor(
		private apiService: ApiService,
		private activeRoute: ActivatedRoute,
		private ideaService: IdeaService,
		public urlService: UrlService,
		public translateService: TranslateService
	) { }

	ngOnInit(): void {
		this.apiService
			.getIdeas()
			.subscribe((data) => {
				this.ideas = data;
			});

		this.activeRoute
			.params
			.subscribe(routeParams => {
				this.ideaService
					.getIdeaInfo(routeParams.id)
					.subscribe((data) => {
						this.ideaInfo = data;
						this.clearSlides();
						this.loadSlides();
						this.loadTripCards();
						// console.log(this.ideaInfo);
					});
			});
	}

	/**
	 * Popolo la lista delle tripCard della destinazione
	 * @returns void
	 */
	loadTripCards() {
		if (!(this.ideaInfo?.elencoViaggi?.length > 0))
			return;

		this.tripCards = [];
		this.ideaInfo.elencoViaggi
			.forEach(trip => {
				let _tripCard = new TripCard();
				_tripCard.completeByTrip(trip);
				this.tripCards.push(_tripCard);
			});
	}

	/**
	 * Popolo la lista delle immagini della destinazione per il carousel
	 * @returns void
	 */
	loadSlides(): void {
		if (this.ideaInfo.coverImageUrl) {
			img: this.ideaInfo.coverImageUrl;
		}
		// if (this.ideaInfo.elencoImmagini) {
		// 	this.ideaInfo.elencoImmagini
		// 		.forEach(image => {
		// 			if (image.url?.length > 0) {
		// 				this.slides.push({
		// 					img: image.url
		// 				})
		// 			}
		// 		});
		// }
		if (this.ideaInfo.elencoViaggi) {
			this.ideaInfo.elencoViaggi
				.forEach(viaggio => {
					this.slides.push({
						img: viaggio.cover
					}
					)
				})
		}
	}

	clearSlides(): void {
		this.slides = [];
	}

	/**
	 * Restituisce una classe specifica in funzione dell'index della TripCard.
	 * 	I primi MAX_NUM_OF_BIG_TRIP elementi vengono rappresentati più grandi rispetto agli altri.
	 * @param index index della TripCard
	 * @returns
	 */
	getTripCardGallerySizeClass(index: number): string {
		const widthClass = (index >= MAX_NUM_OF_BIG_TRIP) ? 'col-lg-6' : '';
		return `col-md-12 ${widthClass}`;
	}
}
