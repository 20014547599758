import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'nr-ribbon',
	templateUrl: './ribbon.component.html',
	styleUrls: ['./ribbon.component.scss']
})
export class RibbonComponent implements OnInit {
	@Input() fileType: string = "";

	constructor(
		public translate: TranslateService
	) { }

	ngOnInit(): void {
	}

	getFileType(): string {
		return this.translate.instant('COMPONENTS.RIBBON.ATTACHMENT', { fileType: this.fileType })
	}

}
