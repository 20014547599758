<section class="intro-single-tiny" *ngIf="hasSlides">
	<div class="container">
		<div class="row">
			<div class="col">
				<ngb-carousel [interval]="0">
					<ng-template ngbSlide *ngFor="let slide of slides">
						<div class="slide-adaptive"
							[style.backgroundImage]="getBackgroundImageFromSlide(slide)">
							<div class="container bottom-head">
								<div class="carousel-caption">
									<a [routerLink]="urlService.navigateToTrip(slide.id)">
										<h3>{{ slide.name }}</h3>
										<p [innerHtml]="slide.caption">&nbsp;</p>
									</a>
								</div>
							</div>
						</div>
					</ng-template>
				</ngb-carousel>
			</div>
		</div>
	</div>
</section>

<div class="container">
	<ng-container *ngIf="pageData">
		<div class="row">
			<div class="col-md-12">
				<ng-container *ngFor="let item of getPageData()">
					<ng-template
						[ngTemplateOutlet]="content"
						[ngTemplateOutletContext]="{item:item}"></ng-template>
				</ng-container>
			</div>
		</div>
	</ng-container>

	<section class="banners" *ngIf="banners?.length">
		<div class="row">
			<div class="col-md-6 col-lg-4" *ngFor="let banner of banners">
				<nr-banner-card [banner]="banner"></nr-banner-card>
			</div>
		</div>
    </section>
        
	<ng-container *ngIf="pageData">
		<div class="row">
			<div class="col-md-12">
				<ng-container *ngFor="let item of getPageData(CONTENT_DISPLAY_POSITION_MIDDLE)">
					<ng-template
						[ngTemplateOutlet]="content"
						[ngTemplateOutletContext]="{item:item}"></ng-template>
				</ng-container>
			</div>
		</div>        
	</ng-container>

    <!-- WIDGET -->
    <!-- <ng-container *ngIf="this.widgets">
        <div class="row">
            <div class="col-md-12">
                <nr-widget-card *ngFor="let widget of this.widgets" [widgetData]="widget"></nr-widget-card>
            </div>
        </div>
    </ng-container> -->

	<ng-template #content let-item='item'>
		<ng-container [ngSwitch]="item.entityType">
			<ng-container *ngSwitchCase="eTipoEntita.Articolo">
				<article
					[id]="item.entityId"
					[class]="getStyle(item.style, 'article-style-')">
					<h2 [innerHtml]="item.name"></h2>
					<div [innerHtml]="item.content"></div>
				</article>
			</ng-container>
			<!-- <ng-container *ngSwitchCase="eTipoEntita.Immagine">
				<img class="img-fluid" [src]="item.content" [alt]="item.name">
			</ng-container> -->
		</ng-container>
	</ng-template>
</div>
