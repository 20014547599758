import {Injectable} from '@angular/core';
import {EOperatore, IUrlService, User} from 'nr-common';
import {environment} from 'projects/nr-b2c/src/environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class UrlService implements IUrlService {
	public PAGE_ROUTES = {
		ABOUT: '/about',
		CONTACTS: '/contacts',
		DESTINATIONS: '/destinations',
		HOME: '/home',
		IDEAS: '/ideas',
		OFFERS: '/offers',
		PARTNERS: '/partners',
		TRIP: '/trips',
		ARTICLE: '/article',
		OPENDESTINATION: '/opendestpagedetail'
	};

	constructor(private translate: TranslateService) {
	}

	navigateToDestination(destinationId: number): any {
		return [this.PAGE_ROUTES.DESTINATIONS, destinationId];
	}

	navigateToIdea(ideaId: number): any {
		return [this.PAGE_ROUTES.IDEAS, ideaId];
	}

	navigateToTrip(tripId: number): any {
		return [this.PAGE_ROUTES.TRIP, tripId];
	}

	navigateToArticle(articleId: number): any {
		return [this.PAGE_ROUTES.ARTICLE, articleId];
	}

	navigateToOpenDestination(articleId: number, openDestinationId: number): any {
		return [this.PAGE_ROUTES.OPENDESTINATION, articleId, openDestinationId];
	}

	getArticleUrl(articleId: number): any {
		return `/article/${articleId}`;
	}

	public openExportFile(file) {
	}

	/* public getIntranetAdvHomeUrl(): any[] {
		return [environment.b2bIntranetAdvUrl[this.translate.currentLang]];
	} */
    public getIntranetAdvHomeUrlFromUser(user: User): string {
		let link = "";
        switch (user.operator) {
            case EOperatore.NaarFrance:
                link = environment.b2bIntranetAdvUrl["fr"];
                break;
            case EOperatore.NaarGermany:
                link = environment.b2bIntranetAdvUrl["de"];
                break;
            case EOperatore.NaarBenelux:
                link = environment.b2bIntranetAdvUrl["nl"];
                break;
            default:
            case EOperatore.Naar:
                link = environment.b2bIntranetAdvUrl["it"];
                break;
        }
        return link;
	}

	/* public getIntranetAdvTripListUrl(): any[] {
		return [environment.b2bIntranetAdvTripList[this.translate.currentLang]];
	} */

    public getIntranetAdvTripListUrlFromUser(user: User): string {
        let link = "";
        switch (user.operator) {
            case EOperatore.NaarFrance:
                link = environment.b2bIntranetAdvUrl["fr"];
                break;
            case EOperatore.NaarGermany:
                link = environment.b2bIntranetAdvUrl["de"];
                break;
            case EOperatore.NaarBenelux:
                link = environment.b2bIntranetAdvUrl["nl"];
                break;
            default:
            case EOperatore.Naar:
                link = environment.b2bIntranetAdvUrl["it"];
                break;
        }
        return link;
    }

	public getIntranetUrl() {
		return [environment.b2bIntranetUrl];
	}
}
