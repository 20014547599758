import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {NrSelectableItem} from './nrselectableitem.class';
import {NrMultiSelect} from './nrmultiselect.class';

@Component({
	selector: 'nr-multiselect',
	templateUrl: './nrmultiselect.component.html'
})
export class NrMultiSelectComponent<T> implements OnInit, OnDestroy {
	@Input() public item: T;
	@Input() public multiSelect: NrMultiSelect<T>;
	@Input() public customTemplate: TemplateRef<any>;
	@Input() nrClass: string;

	public context: any = {
		$implicit: this
	};

	public selectableItem: NrSelectableItem<T>;

	ngOnInit(): void {
		this.selectableItem = this.multiSelect.addItem(this.item);
	}

	ngOnDestroy(): void {
		this.multiSelect.removeItem(this.selectableItem);
	}

	changeSelection(value: any) {
		this.selectableItem.selected = !!value;
		this.selectableItem.selectOrder = this.selectableItem.selected ? this.multiSelect.selectOrder++ : null;
		this.multiSelect.itemSelectionChanged();
	}
}
