import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

import {BackendService} from '../backend/backend.service';
import {ELingua, ILocalizedRequest} from '../backend/naar-api-client';

export interface IAutocompleteSearchRequest extends ILocalizedRequest {
	tableName: string;
	limit: number;
	pageNr: number;
	term?: string;
}

@Injectable()
export class AutocompleteSearchService {

	private _autocompleteServices$: Observable<any[]> = null;
	private _autocompletePlaces$: Observable<any[]> = null;

	constructor(private backend: BackendService, private translate: TranslateService) {
		translate.onLangChange.subscribe(_ => {
			this._autocompleteServices$ = null;
		});
	}

	public getLingua(): ELingua {
		switch (this.translate.currentLang) {
			case 'en':
				return ELingua.English;
			case 'fr':
				return ELingua.Francais;
			case 'de':
				return ELingua.Deutsch;
		}
		return ELingua.Italiano;
	}


	public getAutocompleteSearch(table: string,
								 limit: number = 10,
								 pageNumber: number = 3,
								 searchKey: string = null): Observable<any[]> {

		if (!this._autocompleteServices$) {
			const request: IAutocompleteSearchRequest = {
				lingua: this.getLingua(),
				tableName: table,
				limit: limit,
				pageNr: pageNumber,
				term: searchKey
			};

			this._autocompleteServices$ = this
				.backend
				.call('autocomplete/search', request)
				.pipe(
					map((data: any) => {
						return data;
					}),
					shareReplay(1)
				);
		}

		return this._autocompleteServices$;
	}
}
