import { Component, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { EOperatore, Languages, UserService } from 'nr-common';

import { environment } from '../environments/environment';
import { WebsiteService } from './services/website.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

    //#s1: Subscription;

    constructor(
        translate: TranslateService,
        private websiteService: WebsiteService,
        user: UserService,
        private google: GoogleAnalyticsService,
        router: ActivatedRoute
    ) {

        // OM 08.06.2023: Google Analytics
        this.google.init(user);

        translate.addLangs(['it', 'en', 'fr', 'es', 'de', 'nl']);
        // translate.setDefaultLang('de');
        // translate.setDefaultLang('es');
        // translate.setDefaultLang('en');
        // translate.setDefaultLang('fr');
        translate.setDefaultLang('it');

        /* const url = window.location.href;
        websiteService.setOperatorByUrl(url); */
        
        /* // Così si imposta prima che vengano caricati i contenuti
        this.#s1 = user
        .currentLang$
        .subscribe(lang => {
            translate.use(lang);
        }) */
            
    }
    
    ngOnDestroy(): void {
        //this.#s1?.unsubscribe();
    }
}
