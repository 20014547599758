<div class="container">
	<section class="intro-single">
		<div class="row">
			<div class="col-md-12 col-lg-9">
				<div class="title-single-box">
					<h1 class="title-single">{{ pageHeaderName() }}</h1>
					<span class="color-text-a" [innerHtml]="pageHeaderContent()"></span>
					<hr class="my-4">
					<p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
					<!-- <a class="btn btn-b" [routerLink]="'/'" role="button">Take me Home</a> -->
				</div>
			</div>
			<div class="col-md-12 col-lg-3">
				<!-- <nr-destinations-list></nr-destinations-list> -->
			</div>
		</div>
	</section>

	<!-- <nr-contact-list></nr-contact-list> -->

	<ng-container *ngIf="pageData">
		<nr-contact-form [privacyText]="getPrivacyText()"></nr-contact-form>
		<div class="row">
			<div class="col-sm-12">
				<ng-container *ngFor="let item of pageDataBody">
					<ng-container [ngSwitch]="item.entityType">
						<ng-container *ngSwitchCase="eTipoEntita.Articolo">
							<article>
								<!-- <div class="title-box-d">
								<h3 class="title-d" [innerHtml]="item.name"></h3>
							</div> -->
								<div class="text-description" [innerHtml]="item.content"></div>
							</article>
						</ng-container>
						<ng-container *ngSwitchCase="eTipoEntita.Immagine">
							<img class="img-fluid" [src]="item.content" [alt]="item.name">
						</ng-container>
						<!-- <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultTemplate"></ng-container> -->
					</ng-container>
				</ng-container>
			</div>
		</div>
	</ng-container>
</div>
