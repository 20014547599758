import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import { Destination } from 'nr-common';
import { ApiService } from '../../../services/api.service';
import { CommonService } from '../../../services/common.service';
import { UrlService } from '../../../services/url.service';

const BG_COLOR_LIGHTER_ALFA_CHANNEL = "22";

@Component({
	selector: 'nr-destinations-list',
	templateUrl: './destinations-list.component.html',
	styleUrls: ['./destinations-list.component.scss']
})
export class DestinationsListComponent implements OnInit {
	@Input() destinations: Destination[];

	constructor(
		private apiService: ApiService,
		public urlService: UrlService,
		private commonService: CommonService
	) { }

	ngOnInit(): void {
		if (!this.destinations) {
			this.apiService.getDestinations().subscribe((data) => {
				this.destinations = data;
			});
		}
	}

	getLightBgColor(bgColor: string): string {
		if (!bgColor) return;
		if (!bgColor.startsWith("#")) {
			bgColor = "#" + bgColor;
		}
		return `${bgColor}${BG_COLOR_LIGHTER_ALFA_CHANNEL}`;
	}

	getIcon(iconGliph: string): string {
		if (!iconGliph) {
			iconGliph = 'icon-people';
		}
		return iconGliph;
	}

	transformName(name: string): string {
		return this.commonService.capitalizeAllWords(name);
	}
}
