
export { GoogleMapsAPIWrapper } from './services/google-maps-api-wrapper';

export {
    MAPS_API_CONFIG,
    MapsAPILoader,
    MapsAPILoaderConfig,
} from './utils/maps-api-loader';

export { FitBoundsAccessor, FitBoundsDetails } from './services/fit-bounds.service';

export { MarkerManagerService } from './services/managers/marker-manager.service';
export { InfoWindowManagerService } from './services/managers/info-window-manager.service';
export { PolylineManagerService } from './services/managers/polyline-manager.service';
//export { RouteManagerService } from './services/managers/route-manager.service';
export { PolygonManagerService } from './services/managers/polygon-manager.service';
export { CircleManagerService } from './services/managers/circle-manager.service';
export { RectangleManagerService } from './services/managers/rectangle-manager.service';
export { AerialViewManagerService } from './services/managers/aerial-view-manager.service';

/* export { KmlLayerManager } from './services/managers/kml-layer-manager';
export { LayerManager } from './services/managers/layer-manager';
export { NoOpMapsAPILoader } from './services/maps-api-loader/noop-maps-api-loader';
export { RectangleManager } from './services/managers/rectangle-manager'; */
