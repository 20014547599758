import {Component, forwardRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {formatNumber} from '@angular/common';

@Component({
	selector: 'nr-float',
	templateUrl: './nrfloat.component.html',
	styleUrls: ['./nrfloat.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => NrFloatComponent),
		}
	]
})
export class NrFloatComponent implements ControlValueAccessor, OnChanges {

	public value: number = null;
	public viewValue = '';
	public disabled = false;

	@Input() decimals = 2;
	@Input() appendText: string;
	@Input() prependText: string;
	@Input() isInvalid = false;

	constructor() {
	}

	onChange(_) {
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	writeValue(obj: any): void {
		if (obj || obj === 0) {
			this.value = Number(obj);
			this.viewValue = formatNumber(this.value, 'it', `1.${this.decimals}-${this.decimals}`);
		} else {
			this.value = null;
			this.viewValue = '';
		}
	}

	changed(newViewValue: string) {
		newViewValue = newViewValue.replace(',', '.');
		this.value = parseFloat(newViewValue);
		this.writeValue(this.value);
		this.onChange(this.value);
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.writeValue(this.value);
	}
}
