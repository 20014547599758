/* tslint:disable:variable-name */
import {Injectable} from '@angular/core';
import {
	B2cArticle,
	BackendService,
	BaseDataService,
	Destination,
	ELingua,
	GetB2cArticleResponse,
	GetB2cImageUrlResponse,
	GetMenuDataResponse,
	GetPageDataResponse,
	GetPageFooterResponse,
	Idea,
	IGetB2cArticleRequest,
	IGetB2cImageUrlRequest,
	IGetPageDataRequest,
	IGetPageFooterRequest,
	ISiteRequest,
	MenuData,
	OpenDestination,
	PageData,
	TripInfo,
} from 'nr-common';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {DestinationService} from './destination.service';
import {IdeaService} from './idea.service';
import {TripService} from './trip.service';
import {WebsiteService} from './website.service';

/**
 * SERVIZIO PER LE COMUNICAZIONI COL SERVER
 * --------------------------------------------------------------
 * Per abbattere il numero di chiamate al server.
 * Mantiene in cache locale:
 *	- il menu
 *	- l'insieme delle destinazioni
 *	- dati della pagina
 */
const MENU_ENDPOINT = 'b2c/menu';
const PAGE_ENDPOINT = 'b2c/pagedata';
const FOOTER_ENDPOINT = 'b2c/footer';
const ARTICLE_ENDPOINT = 'b2c/article';
const INFOSTRIP_ENDPOINT = 'b2c/infostrip';
const IMAGE_URL_ENDPOINT = 'b2c/imageUrl';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	public loadingData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	// cache containers
	//private siteRequest: ISiteRequest; // SiteRequest usati per chiamate server
	private cache_menu$: Observable<MenuData[]> = null;
	private cache_infostrip$: Observable<MenuData[]> = null;
	private cache_pageData$: Observable<PageData>[] = [];
	private cache_pageFooter$: Observable<PageData>[] = [];
	private cache_articles$: Observable<B2cArticle>[] = [];

	constructor(
		private backend: BackendService,
		public website: WebsiteService,
		private baseData: BaseDataService,
		private destinationService: DestinationService,
		private ideaService: IdeaService,
		private tripService: TripService
	) {
		/* this.siteRequest = {
			siteID: this.website.currentWebsite.id,
			lingua: this.baseData.getLingua(),
		}; */
	}

	/**
	 * Recupera le voci di menu
	 * @returns Observable<MenuData[]>
	 */
	public getMenu(): Observable<MenuData[]> {
		if (this.cache_menu$ == null) {
			try {
				this.loadingData$.next(true);
                const siteRequest: ISiteRequest = {
                    siteID: this.website.currentWebsite.id,
                    lingua: this.baseData.getLingua(),
                }
				this.cache_menu$ = this.backend.call(MENU_ENDPOINT, siteRequest).pipe(
					map((data: GetMenuDataResponse) => {
						return data.menuData;
					}),
					shareReplay(1)
				);
			} catch (error) {
				console.error(error);
			} finally {
				this.loadingData$.next(false);
			}
		}

		return this.cache_menu$;
	}

	/**
	 * Recupera le voci della info-strip
	 * @returns Observable<MenuData[]>
	 */
	public getInfostrip(): Observable<MenuData[]> {
		if (this.cache_infostrip$ == null) {
			try {
				this.loadingData$.next(true);
                const siteRequest: ISiteRequest = {
                    siteID: this.website.currentWebsite.id,
                    lingua: this.baseData.getLingua(),
                }
				this.cache_infostrip$ = this.backend.call(INFOSTRIP_ENDPOINT, siteRequest).pipe(
					map((data: GetMenuDataResponse) => {
						return data.menuData;
					}),
					shareReplay(1)
				);
			} catch (error) {
				console.error(error);
			} finally {
				this.loadingData$.next(false);
			}
		}

		return this.cache_infostrip$;
	}

	/**
	 * Recupera i dati della pagina
	 * @param pageId ID della pagina
	 * @returns Observable<PageData[]>
	 */
	public getPageData(pageId: number): Observable<PageData> {
		
        const actualLanguage = this.baseData.getLingua();
        const cacheID = `${pageId}_${actualLanguage}`;

        if (this.cache_pageData$[cacheID] == null) {
			try {
				this.loadingData$.next(true);
				const localRequest: IGetPageDataRequest = {
					siteID: this.website.currentWebsite.id,
                    lingua: actualLanguage,
					pageDataID: pageId,
				};

                this.cache_pageData$[cacheID] = this.backend.call(PAGE_ENDPOINT, localRequest).pipe(
					map((data: GetPageDataResponse) => {
						return data.pageData;
					}),
					shareReplay(1)
				);
			} catch (error) {
				console.error(error);
			} finally {
				this.loadingData$.next(false);
			}
		}

        return this.cache_pageData$[cacheID];
	}

	/**
	 * Recupera i dati della pagina
	 * @param pageId ID della pagina
	 * @returns Observable<PageData[]>
	 */
    public getPageFooter(pageId: number): Observable<GetPageFooterResponse> {
        
        const actualLanguage = this.baseData.getLingua();
        const cacheID = `${pageId}_${actualLanguage}`;

        if (this.cache_pageFooter$[cacheID] == null) {
			try {
				this.loadingData$.next(true);
				const localRequest: IGetPageFooterRequest = {
					siteID: this.website.currentWebsite.id,
                    lingua: actualLanguage,
					pageDataID: pageId,
				};

                this.cache_pageFooter$[cacheID] = this.backend.call(FOOTER_ENDPOINT, localRequest).pipe(
					map((data: GetPageFooterResponse) => {
						return data;
					}),
					shareReplay(1)
				);
			} catch (error) {
				console.error(error);
			} finally {
				this.loadingData$.next(false);
			}
		}

        return this.cache_pageFooter$[cacheID];
	}

	/**
	 * Recupera i dati delle destinazioni
	 * @returns Observable<Destination[]>
	 */
	public getDestinations(): Observable<Destination[]> {
		return this.destinationService.getDestinations();
	}

	/**
	 * Recupera i dati delle destinazioni aperte
	 * @returns Observable<OpenDestination[]>
	 */
	public getOpenDestinations(): Observable<OpenDestination[]> {
		return this.destinationService.getOpenDestinations();
	}

	/**
	 * Recupera i dati delle idee di viaggio
	 * @returns Observable<Idea[]>
	 */
	public getIdeas(): Observable<Idea[]> {
		return this.ideaService.getIdeas();
	}

	/**
	 * Recupera i dati del viaggio
	 * @returns Observable<TripInfo>
	 */
	public getTrip(tripId: number): Observable<TripInfo> {
		return this.tripService.getTripInfo(tripId);
	}

	/**
	 * Recupera le voci di menu
	 * @params	articleID
	 * @params	parseToken se true forza il parsing e l'espansione dei token nel testo
	 * @returns Observable<B2cArticle>
	 */
	public getArticle(articleId: number, parseToken: boolean = false): Observable<B2cArticle> {

        const actualLanguage = this.baseData.getLingua();
        const cacheID = `${articleId}_${actualLanguage}`;


        if (this.cache_articles$[cacheID] == null) {
			try {
				this.loadingData$.next(true);
				const localRequest: IGetB2cArticleRequest = {
					articleID: articleId,
                    lingua: actualLanguage,
					parseToken: parseToken,
				};

                this.cache_articles$[cacheID] = this.backend.call(ARTICLE_ENDPOINT, localRequest).pipe(
					map((data: GetB2cArticleResponse) => {
						return data.article;
					}),
					shareReplay(1)
				);
			} catch (error) {
				console.error(error);
			} finally {
				this.loadingData$.next(false);
			}
		}

        return this.cache_articles$[cacheID];
	}

	/* public takeMeHome(lang: string = ''): string {
		let result: string = `home/${lang}`;
		return result;
	} */
}
