import {
	Component,
	OnInit
} from '@angular/core';
import { TranslateService }  from '@ngx-translate/core';
import { Title }             from '@angular/platform-browser';
import { ApiService }        from '../../services/api.service';
import { WebsiteService }    from '../../services/website.service';
import { BasePageComponent } from '../../shared/components/base-page/base-page.component';
import {
	Destination,
	OpenDestination
} from 'nr-common';

@Component({
	selector: 'nr-opendestpage',
	templateUrl: './opendestpage.component.html',
	styleUrls: ['./opendestpage.component.scss']
})
export class OpendestpageComponent extends BasePageComponent implements OnInit {
	public destinations: Destination[];
	public openDestinations: OpenDestination[];

	constructor(
		apiService: ApiService,
		webSiteService: WebsiteService,
		titleService: Title,
		translateService: TranslateService,
	) {
		super(apiService, webSiteService, titleService, translateService);
		this.pageName = 'opendestpage';
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.apiService.getDestinations().subscribe((data) => {
			this.destinations = data;
			// console.log("opendestpage", this.destinations);
		});

		this.apiService.getOpenDestinations().subscribe((data) => {
			this.openDestinations = data;
			// console.log("opendestpage", this.openDestinations);
		});
	}

}
