import {Component, Input} from '@angular/core';
import {NrMultiSelect} from './nrmultiselect.class';

@Component({
	selector: 'nr-multiselect-all',
	templateUrl: './nrmultiselectall.component.html'
})
export class NrMultiSelectAllComponent {
	@Input() public multiSelect: NrMultiSelect<any>;
	@Input() nrClass: string;
	@Input() nrLabel: string;
	@Input() nrLabelClass: string;
	@Input() id: string;

	changeSelection(value: any) {
		this.multiSelect.changeAllSelected(!!value);
	}

}
