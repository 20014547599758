<!-- <section class="intro-top">&nbsp;</section> -->
<section class="intro-single">
	<div class="container">
		<div class="row">
			<div class="col">
				<div class="title-single-box">
					<h1 class="title-single">{{ pageHeaderName(destinationInfo?.nome) }}
						<!-- <div class="cover-icon"><i class="{{ getIcon(destinationInfo?.gaIcon)}}"></i></div> -->
					</h1>
				</div>
			</div>
		</div>

		<div class="row no-gutters">
			<div class="col-md-12 col-lg-9">
				<div id="gallery">
					<ngx-slick-carousel class="carousel"
						*ngIf="slides.length > 0; else onlyCover"
						#slickModal="slick-carousel"
						[config]="slideConfig">
						<div ngxSlickItem
							*ngFor="let slide of slides"
							class="slide">
							<img src="{{ slide.img }}"
								alt=""
								width="100%">
						</div>
					</ngx-slick-carousel>
					<ng-template #onlyCover>
						<img src="{{ coverImageUrl }}"
							alt="{{ destinationInfo?.nome }}">
					</ng-template>
				</div>
			</div>
			<div class="col-md-12 col-lg-3">
				<nr-destinations-list [destinations]="destinations"></nr-destinations-list>
			</div>
		</div>
	</div>
</section>

<section class="property-single nav-arrow-b">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="row justify-content-between">
					<div class="col-md-6 section-md-t3">
						<div class="text-description"
							[innerHtml]="destinationInfo?.abstract"></div>
					</div>
					<div class="col-md-6">
						<div class="row">
							<div *ngFor="let tripCard of tripCards; let i = index"
								class="mb-3 col-sm-12"
								[attr.data-index]="i"
								[ngClass]="getTripCardGallerySizeClass(i)">
								<nr-trip-card [trip]="tripCard">
								</nr-trip-card>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
