import {IAgenciesListRow, TripHead} from 'nr-common';

/**
 * Struttura dati dei contatti
 */
export class Contact {
	public Cognome: string;
	public Nome: string;
	public Email: string;
	public ViaggioDiNozze: boolean;
	public Budget: string;
	public Note: string;
	public selectedADV: IAgenciesListRow;
	public interestedTrip: TripHead[];
	public selectedTrip: number;


	constructor() {
		this.Cognome = '';
		this.Nome = '';
		this.Email = '';
		this.ViaggioDiNozze = false;
		this.Budget = 'INDEFINITO';
		this.Note = '';
		this.interestedTrip = new Array<TripHead>(0);
		this.selectedTrip = 0;
		this.selectedADV = {
			contactID: 1,
			nome: 'NAAR TOUR OPERATOR',
			indirizzo: 'Via Mario Pagano, 40',
			prov: 'MI',
			luogo: 'Milano'
		};
	}
}
