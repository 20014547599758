import { ArticoloGetResponse, GetDocumentoFrasiResponse } from '../backend/naar-api-client';

/**
 * Elenco dei tipi di messaggio
 */
export enum ENrMessageType {
    /**
     * Handshake all'inizio della comunicazione
     */
    Handshake,

    /**
     * Sessione di editing di un articolo terminata
     */
    ArticleEditDone,

    /**
     * Sessione di editing di un documento terminata
     */
    DocumentEditDone
}

/**
 * Classe base dei messaggi
 */
export abstract class NrMessage {
    /**
     * Costante per identificare un nrMessage da un any
     */
    public readonly nrMessage = true;

    /**
     * Tipo di messaggio, impostato dalle classi derivate
     */
    public type: ENrMessageType;
}

/**
 * Messaggio di handshake. In msg.ports[0] c'è la porta di comunicazione del canale
 */
export class HandshakeMessage extends NrMessage {
    constructor() {
        super();
        this.type = ENrMessageType.Handshake;
    }
}

/**
 * Messaggio inviato al termine di una sessione di edit di un articolo
 */
export class ArticleEditDone extends NrMessage {

    /**
     * Dati dell'articolo modificato. Null se non ci sono state modifiche
     */
    public datiArticolo: ArticoloGetResponse;

    /**
     * Costruttore di default
     * @param datiArticolo Dati dell'articolo modificato. Null se non ci sono stati cambiamenti
     */
    constructor(datiArticolo: ArticoloGetResponse) {
        super();
        this.type = ENrMessageType.ArticleEditDone;
        this.datiArticolo = datiArticolo;
    }
}

/**
 * Messaggio inviato al termine di una sessione di edit di un documento
 */
export class DocumentEditDone extends NrMessage {

    /**
     * Dati del documento modificato. Null se non ci sono state modifiche
     */
    public datiDocumento: GetDocumentoFrasiResponse;

    /**
     * Costruttore di default
     * @param datiDocumento Dati del documento modificato. Null se non ci sono stati cambiamenti
     */
    constructor(datiDocumento: GetDocumentoFrasiResponse) {
        super();
        this.type = ENrMessageType.DocumentEditDone;
        this.datiDocumento = datiDocumento;
    }
}