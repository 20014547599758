import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import { TripItineraryDay } from 'nr-common';

@Component({
	selector: 'nr-tappe',
	templateUrl: './tappe.component.html',
	styleUrls: ['./tappe.component.scss']
})
export class TappeComponent implements OnInit {
	@Input() tappe: TripItineraryDay[];

	constructor() { }

	ngOnInit(): void {
	}

}
