<kendo-textbox type="text"
	[class.is-invalid]="isInvalid"
	[disabled]="disabled"
	[ngModel]="viewValue"
	(ngModelChange)="changed($event)"
	[ngModelOptions]="{ updateOn: 'blur' }">
	<ng-template kendoTextBoxPrefixTemplate
		*ngIf="prependText">
		<span class="px-1">{{ prependText }}</span>
	</ng-template>
	<ng-template kendoTextBoxSuffixTemplate
		*ngIf="appendText">
		<span class="px-1">{{ appendText }}</span>
	</ng-template>
</kendo-textbox>

<!-- <div class="input-group input-group-sm">
	<div class="input-group-prepend" *ngIf="prependText">
		<span class="input-group-text">{{ prependText }}</span>
	</div>
	<input
		kendoTextBox
		type="text"
		[class.is-invalid]="isInvalid"
		[disabled]="disabled"
		[ngModel]="viewValue"
		(ngModelChange)="changed($event)"
		[ngModelOptions]="{ updateOn: 'blur' }"/>
	<div class="input-group-append" *ngIf="appendText">
		<span class="input-group-text">{{ appendText }}</span>
	</div>
</div> -->
