import {
	Component,
	OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
	Languages,
	UiUserOptions,
	UserService
} from 'nr-common';
import {
	NgbDropdown
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NotifierService } from 'projects/nr-b2b/src/app/services/notifier/notifier.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';


@Component({
	selector: 'nr-flags',
	templateUrl: './nr-flags.component.html',
	styleUrls: ['./nr-flags.component.scss']
})
export class NrFlagsComponent implements OnInit {
	public actualLanguages: Languages[] = ['it', 'fr', 'en', 'de', 'es'];

	constructor(
		// private localeService: BsLocaleService,
		// private notifier: NotifierService,
		public user: UserService,
		public translate: TranslateService
	) { }

	ngOnInit(): void {
	}

	public getUnselectedLanguages(): Languages[] {
		return this.actualLanguages.filter(i => i !== this.translate.currentLang);
	}

	getLanguageClass(lang: Languages = null): string {
		let cname = this.translate.currentLang.toUpperCase();

		if (lang) {
			cname = lang;
		}

		cname = 'ul-' + cname.toUpperCase();

		return `user-lang ${cname}`;
	}

	getLanguage(lang: Languages): string {
		return this.translate.instant('LANGUAGES.' + lang.toUpperCase());
	}

	setUserLanguage(lang: Languages) {
		this.translate.use(lang);
		moment.locale(lang);
		// this.localeService.use(lang);
		const lingua = this.translate.instant('LANGUAGES.' + lang.toUpperCase());
		const msg = this.translate.instant('NAVBAR.SALVARE_LINGUA_DEFAULT', { lingua });

		const uiOptions = new UiUserOptions();
		Object.assign(uiOptions, this.user.uiOptions);
		uiOptions.lang = lang;
		//this.user.writeOptions(this.user.appOptions, uiOptions);

		// this.notifier.yesNo(msg, () => {
		// 	const uiOptions = new UiUserOptions();
		// 	Object.assign(uiOptions, this.user.uiOptions);
		// 	uiOptions.lang = lang;
		// 	this
		// 		.user
		// 		.writeOptions(this.user.appOptions, uiOptions)
		// 		.then(() => {
		// 			this.notifier.success(this.translate.instant('NAVBAR.LINGUA_DEFAULT_SALVATA'));
		// 		})
		// 		.catch(err => this.notifier.error(err));
		// });
	}
}
