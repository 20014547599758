import {Injectable} from '@angular/core';
import {IAgenciesListRow, TinySelectedAdv, TinyTripOfInterest, TripHead, TripInfo} from 'nr-common';
import {Contact} from '../shared/models/contact.model';

@Injectable({
	providedIn: 'root'
})
export class SherpaService {
	// Array degli indici dei viaggi visti
	public ContactData: Contact;

	constructor() {
		this.ContactData = new Contact();
	}

	addTripOfInterest(trip: TripHead) {
		if (trip === undefined) {
			console.error('Trip undefined!');
			return;
		}
		const found = this.ContactData.interestedTrip.some(el => el.tripID === trip.tripID);
		if (!found) {
			this.ContactData.interestedTrip.push(trip);
		}
	}

	addTripOfInterestByCompleteTrip(trip: TripInfo) {
		if (trip === undefined) {
			console.error('Trip undefined!');
			return;
		}
		let t: TripHead;

		t = new TripHead({...(trip as TripHead)});	// Shallow Copy Using the spread operator
		this.addTripOfInterest(t);
	}

	getTripOfInterestList(): TripHead[] {
		return this.ContactData.interestedTrip;
	}

	getTripOfInterestNumber(): number {
		return this.ContactData.interestedTrip.length;
	}

	hasTripOfInterest() {
		if (this.ContactData.interestedTrip === undefined) {
			return false;
		}
		return this.getTripOfInterestNumber() > 0;
	}

	addSelectedADV(data: IAgenciesListRow) {
		this.ContactData.selectedADV = data;
	}

	getSelectedAdvID(): number {
		return this.ContactData.selectedADV.contactID ?? 0;
	}

	getSelectedAdv() {
		return this.ContactData.selectedADV;
	}

	getTinyTripOfInterest(): TinyTripOfInterest[] {
		const arTrips: TinyTripOfInterest[] = new Array<TinyTripOfInterest>(0);
		this.ContactData.interestedTrip.forEach(element => {
			arTrips.push(new TinyTripOfInterest({
				id: element.tripID.toString(),
				nome: element.nome,
				durata: `${element.durata.giorni}gg/${element.durata.notti}nn`
			}));
		});
		return arTrips;
	}

	getTinySelectedAdv(): TinySelectedAdv {
		return new TinySelectedAdv({
			contactID: this.ContactData.selectedADV.contactID.toString(),
			nome: this.ContactData.selectedADV.nome,
			telefono: this.ContactData.selectedADV.telefono,
			indirizzo: `${this.ContactData.selectedADV.indirizzo} - (${this.ContactData.selectedADV.prov}) - ${this.ContactData.selectedADV.luogo}`
		});
	}

	setSelectedTrip(trip: TripHead) {
		this.ContactData.selectedTrip = trip?.tripID;
		// console.log(trip);
	}

	isSelectedTrip(trip: TripHead): boolean {
		return this.ContactData.selectedTrip === trip?.tripID;
	}

	hasSelectedTrip(): boolean {
		return this.ContactData.selectedTrip !== 0;
	}
}

