<div class="info-strip"
	[class.navbar-solid]="hide">
	<div class="container">
		<nav class="navbar-expand-lg">
			<ul class="navbar-nav">
				<li class="nav-item" *ngFor="let item of menuVoices">
					<a routerLinkActive="active"
					[routerLinkActiveOptions]="{exact: false}"
					[routerLink]="item.url">{{ item.nome }}</a>
				</li>
                <li class="nav-spacer">&nbsp;</li>
                
                <li class="nav-item" *ngIf="availableLanguages != null && availableLanguages?.length > 1">
                    <ng-container *ngFor="let l of availableLanguages; let i = index">
                        <a [class.active]="l.codice == this.translate.currentLang"
                            (click)="changeLanguage(l, $event)" href="#">
                             {{("ENUMS.ELingua." + l.linguaID) | translate}}
                        </a>
                        <span class="vertical-spacer" *ngIf="i < availableLanguages.length - 1">&nbsp;|&nbsp;</span>
                    </ng-container>
                </li>

                <!--<li class="nav-item">
                    <a [class.active]="this.currentOperator == EOperatore.Naar" 
                        [title]="'ENUMS.EOperatore.Naar'|translate" 
                        (click)="setOperator(EOperatore.Naar, $event)" href="#">
                        {{'ENUMS.EOperatore.Naar'|translate}}
                    </a>
                    <span class="vertical-spacer">&nbsp;|&nbsp;</span>
                    <a [class.active]="this.currentOperator == EOperatore.NaarFrance" 
                        [title]="'ENUMS.EOperatore.NaarFrance'|translate" 
                        (click)="setOperator(EOperatore.NaarFrance, $event)" href="#">
                        {{'ENUMS.EOperatore.NaarFrance'|translate}}
                    </a>
                    <span class="vertical-spacer">&nbsp;|&nbsp;</span>
                    <a [class.active]="this.currentOperator == EOperatore.NaarGermany" 
                        [title]="'ENUMS.EOperatore.NaarGermany'|translate" 
                        (click)="setOperator(EOperatore.NaarGermany, $event)" href="#">
                        {{'ENUMS.EOperatore.NaarGermany'|translate}}
                    </a>
                    <span class="vertical-spacer">&nbsp;|&nbsp;</span>
                    <a [class.active]="this.currentOperator == EOperatore.NaarBenelux" 
                        [title]="'ENUMS.EOperatore.NaarBenelux'|translate" 
                        (click)="setOperator(EOperatore.NaarBenelux, $event)" href="#">
                        {{'ENUMS.EOperatore.NaarBenelux'|translate}}
                    </a>
                </li> -->
			</ul>
		</nav>
	</div>
</div>
