/* tslint:disable:no-bitwise */
import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CommonService {
	readonly INVERT_ONLY_BLACK_AND_WHITE = true;
	readonly INVERT_REAL_COLOR = false;

	constructor() {
	}

	/**
	 * restituice il colore complementare a quello passato.
	 *
	 * https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color/35970186
	 * Se il flag bw è attivo, viene restituito il bianco per colori scuri o il nero per colori chiari
	 * https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color/3943023#3943023
	 */
	public invertColor(col: string, bw: boolean = this.INVERT_REAL_COLOR): string {
		let result = '000000';
		if (col) {
			if (col.indexOf('#') === 0) {
				col = col.slice(1);
			}
			// convert 3-digit col to 6-digits.
			if (col.length === 3) {
				col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];
			}
			if ((col.length !== 3) && (col.length !== 6) && (col.length !== 8)) {
				throw new Error('Invalid HEX color.');
			}
			// invert color components
			let r = parseInt(col.slice(0, 2), 16);
			let g = parseInt(col.slice(2, 4), 16);
			let b = parseInt(col.slice(4, 6), 16);

			if (bw) {
				r = r * 0.299;
				g = g * 0.587;
				b = b * 0.114;
				result = ((r + g + b) > 186) ? '000000' : 'FFFFFF';
			} else {
				r = 255 ^ r;
				g = 255 ^ g;
				b = 255 ^ b;

				// pad each with zeros and return
				result =
					r.toString(16).padStart(2, '0') +
					g.toString(16).padStart(2, '0') +
					b.toString(16).padStart(2, '0');
			}
		}
		return `#${result}`;
	}

	/**
	 * Restituisce un colore in RGBA, con canale alpha 00..FF
	 * Se omesso, alpha vale per default 'BB' (convenzionalmente una leggera trasparenza)
	 */
	getTransparentColor(col: string, alpha: string = 'BB'): string {
		if (col) {
			if (col.indexOf('#') === 0) {
				col = col.slice(1);
			}
			if (col.length === 3) {
				col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];
			}
			if (col.length !== 6) {
				throw new Error('Invalid HEX color.');
			}
		}
		return `#${col}${alpha}`;
	}

	/**
	 * Mette in maiuscolo tutte le parole di una stringa più lunghe di un carattere
	 * @param name string
	 * @param minLength number	lunghezza minima della parola da capitalizzare
	 * @returns string
	 */
	capitalizeAllWords(name: string, minLength: number = 1): string {
		name = name || '';
		if (name === '') return '';

		const arrayName = name.split(' ');
		for (let i = 0; i < arrayName.length; i++) {
			if (arrayName[i].length < minLength)
				continue;
			arrayName[i] = arrayName[i].charAt(0).toUpperCase() + arrayName[i].slice(1).toLowerCase();
		}

		return arrayName.join(' ');
	}

	getVoidImage(): string {
		return 'assets/img/Naar-void.png'
	}
}
