import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import { Idea } from 'nr-common';
import { ApiService } from '../../../services/api.service';
import { UrlService } from '../../../services/url.service';

const BG_COLOR_LIGHTER_ALFA_CHANNEL = "22";

@Component({
	selector: 'nr-ideas-list',
	templateUrl: './ideas-list.component.html',
	styleUrls: ['./ideas-list.component.scss']
})
export class IdeasListComponent implements OnInit {
	@Input() ideas: Idea[];

	constructor(
		private apiService: ApiService,
		public urlService: UrlService
	) { }

	ngOnInit(): void {
		if (!this.ideas) {
			this.apiService.getIdeas().subscribe((data) => {
				this.ideas = data;
			});
		}
	}

	getLightBgColor(bgColor: string): string {
		if (!bgColor.startsWith("#")) {
			bgColor = "#" + bgColor;
		}
		return `${bgColor}${BG_COLOR_LIGHTER_ALFA_CHANNEL}`;
	}

	getIcon(iconGliph: string): string {
		if (!iconGliph) {
			iconGliph = 'icon-people';
		}
		return iconGliph;
	}

}
