export type DocLang = 'it' | 'fr' | 'en' | 'de' | 'es';

export interface IHelpFile {
	file: string;
	titolo: string;
	descrizione: string;
	html?: string;
	pdf?: string;
}

export interface IHelpDocument {
	codice: string;
	files: { [lang in DocLang]?: IHelpFile };
}
