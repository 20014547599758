import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {TranslateService} from '@ngx-translate/core';
import {Destination, DestinationInfo} from 'nr-common';
import {ActivatedRoute} from '@angular/router';
import {DestinationService} from '../../services/destination.service';
import {UrlService} from '../../services/url.service';
import {TripCard} from '../../shared/models/trip-card';

const MAX_NUM_OF_BIG_TRIP = 2;	// NUmero massimo di Viaggi mostrati "grandi" nella tripCard gallery

@Component({
	selector: 'nr-destinationpage',
	templateUrl: './destinationpage.component.html',
	styleUrls: ['./destinationpage.component.scss']
})
export class DestinationpageComponent implements OnInit {
	public destinations: Destination[];
	public destinationInfo: DestinationInfo;
	tripCards: TripCard[] = [];

	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: '<div class="nav-btn next-slide"><i class="fa fa-chevron-right"></i></div>',
		prevArrow: '<div class="nav-btn prev-slide"><i class="fa fa-chevron-left"></i></div>',
		dots: true,
		infinite: true,
		swipe: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	slides = [];

	constructor(
		private apiService: ApiService,
		private activeRoute: ActivatedRoute,
		private destinationService: DestinationService,
		public urlService: UrlService,
		public translateService: TranslateService
	) {
	}

	ngOnInit(): void {
		this.apiService
			.getDestinations()
			.subscribe((data) => {
				this.destinations = data;
			});

		this.activeRoute
			.params
			.subscribe(routeParams => {
				this.destinationService
					.getDestinationInfo(routeParams.id)
					.subscribe((data) => {
						this.destinationInfo = data;
						this.clearSlides();
						this.loadSlides();
						this.loadTripCards();
					});
			});
	}

	/**
	 * Popolo la lista delle tripCard della destinazione
	 * @returns void
	 */
	loadTripCards() {
		this.tripCards = [];
		if (!(this.destinationInfo?.elencoViaggi?.length > 0))
			return;

		this.destinationInfo.elencoViaggi
			.forEach(trip => {
				let _tripCard = new TripCard();
				_tripCard.completeByTrip(trip);
				this.tripCards.push(_tripCard);
			});
	}

	/**
	 * Popolo la lista delle immagini della destinazione per il carousel
	 * @returns void
	 */
	loadSlides(): void {
		if (this.destinationInfo.elencoImmagini) {
			this.destinationInfo.elencoImmagini
				.forEach(image => {
					if (image.url?.length > 0) {
						if (this.slides.find(({img}) => img === image.url) === undefined) {
							this.slides.push({
								img: image.url
							})
						}
					}
				});
		}
		if (this.destinationInfo.elencoViaggi) {
			this.destinationInfo.elencoViaggi
				.forEach(viaggio => {
					this.slides.push({
						img: viaggio.cover
					}
					)
				})
		}
	}

	clearSlides(): void {
		this.slides = [];
	}

	/**
	 * Restituisce una classe specifica in funzione dell'index della TripCard.
	 * 	I primi MAX_NUM_OF_BIG_TRIP elementi vengono rappresentati più grandi rispetto agli altri.
	 * @param index index della TripCard
	 * @returns
	 */
	getTripCardGallerySizeClass(index: number): string {
		const widthClass = (index >= MAX_NUM_OF_BIG_TRIP) ? 'col-lg-6' : '';
		return `col-md-12 ${widthClass}`;
	}

	public pageHeaderName(name: string) {
		name = name || '';
		if (name == '') return '';

		const arrayName = name.split(" ");
		for (var i = 0; i < arrayName.length; i++) {
			if (arrayName[i].length < 2)
				continue;
			arrayName[i] = arrayName[i].charAt(0).toUpperCase() + arrayName[i].slice(1);
		}
		return arrayName.join(" ");
	}

	getIcon(iconGliph: string): string {
		if (!iconGliph) {
			iconGliph = 'icon-people';
		}
		return iconGliph;
	}
}

/**
 * https://www.npmjs.com/package/ngx-slick-carousel
 */
