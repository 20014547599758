<!-- <img class="img-fluid" src="https://via.placeholder.com/600x750/a49ea4/007777?text=Mappa" alt="mappa"> -->
<!-- https://stackoverflow.com/questions/50479434/can-i-give-an-anchor-to-a-marker-in-agm-core -->
<div class="map-box">
	<nr-maps mapId="MAPPA" *ngIf="mappa"
		style="width: 100%; height: 100%;"
		[fitBounds]="true"
		[fullscreenControl]="true"
		[zoom]="mappa.zoom"
		[disableDefaultUI]="false"
		[zoomControl]="false"
		[latitude]="mappa.latitudine"
		[longitude]="mappa.longitudine"
		[scrollwheel]="false"
		(mapReady)="onMapReady($event)">

		<nr-maps-marker *ngFor="let i of tappe"
			[iconUrl]="{url: '/assets/img/Marker-white.png',  anchor : {x:9, y:9}}"
			[agmFitBounds]="true"
			[title]="getTitle(i)"
			[latitude]="i.lat"
			[longitude]="i.lon">
		</nr-maps-marker>
		<nr-maps-polyline [geodesic]="true" strokeColor="#00f">
			<nr-maps-polyline-point *ngFor="let p of tappe"
			[latitude]="p.lat"
			[longitude]="p.lon"></nr-maps-polyline-point>
		</nr-maps-polyline>
	</nr-maps>
</div>
