<div class="fixed-top">
	<nav class="navbar navbar-default navbar-trans navbar-expand-lg"
		[class.navbar-solid]="solid">
		<div class="container nb-pad">
			<button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarDefault" aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
				<span></span>
				<span></span>
				<span></span>
			</button>
			<a class="navbar-brand text-brand" [routerLink]="['/']">
				<div class="logo">
					<img src="{{ getDefaultLogo()}}" class="brand_logo" alt="Logo">
				</div>
			</a>
			<!-- <button type="button" class="btn btn-link nav-search navbar-toggle-box-collapse d-md-none" data-toggle="collapse" data-target="#navBarToggleSearchPanel" aria-expanded="false">
				<span class="fa fa-search" aria-hidden="true"></span>
			</button> -->
			<div class="navbar-collapse collapse justify-content-center" id="navbarDefault">
				<ul class="navbar-nav">
					<!-- <li class="nav-item" *ngFor="let item of menuVoices">
						<a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" [routerLink]="item.url">{{ item.nome }}</a>
					</li> -->
					<li class="nav-spacer">&nbsp;</li>
					<li class="nav-item">
						<div class="d-inline-block"
							ngbDropdown
							#chooseOperatorDropDown="ngbDropdown"
							autoClose="outside">

							<a class="nav-link"
								id="ddChooseOperator"
								title="{{'NAV-BAR.MENU-OPERATORE'|translate}}"
								ngbDropdownToggle>
								<span>{{ 'NAV-BAR.Operatore' | translate }}</span>&nbsp;({{ 'ENUMS.EOperatore.' + currentOperator | translate}})
							</a>

							<ul ngbDropdownMenu
								aria-labelledby="ddChooseOperator">
								<li class="dropdown-item"
									*ngFor="let operator of website.operatorLinks">
									<a [class.disabled]="operator.idOperator==currentOperator"
										href="{{operator.link}}">{{ operator.name }}</a>
								</li>
							</ul>
						</div>
					</li>
					<li class="nav-item">
						<a class="nav-link"
							data-toggle="modal" data-target="#mySignin"
							*ngIf="!user.isLoggedIn"
							(click)="open(mySignin)">{{ 'NAV-BAR.ACCEDI' | translate }}
						</a>
						<!-- se loggato mostro nome utente -->
						<div class="d-inline-block" ngbDropdown #userDropDown="ngbDropdown"
							*ngIf="user.isLoggedIn"
							autoClose="outside">
							<a class="nav-link" id="ddIntraMenu" title="{{'NAV-BAR.MENU-UTENTE'|translate}}" ngbDropdownToggle>
								<i class="fa fa-user" [title]="getUserName()" [title]="getUserId()"></i>&nbsp;
								<span class="user-data" [innerHTML]="getUserName()"></span>&nbsp;
							</a>

							<ul ngbDropdownMenu aria-labelledby="ddIntraMenu">
								<ng-container *ngIf="website.showAdvancedControls$|async">
									<li class="dropdown-item"><a href="{{ url.getIntranetUrl() }}"><span class="fa fa-laptop"></span>&nbsp; <span>{{ 'NAV-BAR.INTRANET'|translate }}</span></a></li>
									<li class="dropdown-divider"></li>
								</ng-container>
								<li class="dropdown-item"><a href="{{ url.getIntranetAdvHomeUrlFromUser(user.currentUser) }}"><span class="fa fa-star"></span>&nbsp; <span>{{ 'NAV-BAR.AREA-AGENZIE'|translate }}</span></a></li>
								<li class="dropdown-item"><a href="{{ url.getIntranetAdvTripListUrlFromUser(user.currentUser) }}" (click)="userDropDown.close()"><span class="fa fa-briefcase"></span>&nbsp; <span>{{ 'NAV-BAR.I-TUOI-VIAGGI'|translate }}</span></a></li>
									<li class="dropdown-divider"></li>
								<li class="dropdown-item"><a class="hand-pointer" (click)="doLogOut()"><span class="fa fa-briefcase"></span>&nbsp; <span>{{ 'NAV-BAR.LOGOUT'|translate }}</span></a></li>
								<!-- <ng-container *ngIf="user.can(['intranet'])">
									<li class="dropdown-divider"></li>
									<li class="dropdown-item">
										<div class="custom-control custom-switch">
											<input type="checkbox" class="custom-control-input" id="toggleViewMode" (ngModelChange)="toggleViewMode()" [ngModel]="(website.siteViewMode$|async)==ESiteViewMode.AgencyLike">
											<label class="custom-control-label" for="toggleViewMode">{{'NAV-BAR.VISUALIZZAZIONE-AGENZIA'|translate}}</label>
										</div>
									</li>
								</ng-container> -->
							</ul>
						</div>
					</li>
					<!-- <li class="nav-item">
					<button type="button" class="btn btn-b-n navbar-toggle-box-collapse d-none d-md-block"
						(click)="toggleSearchPanel()"
						data-toggle="collapse"
						data-target="#navBarToggleSearchPanel"
						aria-expanded="false">
						<span class="fa fa-search" aria-hidden="true"></span>
					</button>
				</li> -->
				</ul>
			</div>
		</div>
	</nav>
	<nr-info-strip [class.hide]="onTop"></nr-info-strip>
</div>
<div id="searchContainer" [ngClass]="getSearchPanelState()">
	<div class="click-closed"></div>
	<div class="box-collapse">
		<div class="title-box-d">
			<h3 class="title-d">Ricerca un viaggio</h3>
		</div>
		<span class="close-box-collapse right-boxed ion-ios-close"
			(click)="closeSearchPanel()"></span>
		<div class="box-collapse-wrap form">
			<form class="form-a">
				<div class="row">
					<div class="col-md-12 mb-2">
						<div class="form-group">
							<label for="Type">Parola chiave</label>
							<input type="text" class="form-control form-control-lg form-control-a" placeholder="Keyword">
						</div>
					</div>
					<div class="col-md-6 mb-2">
						<div class="form-group">
							<label for="Type">Idea</label>
							<select class="form-control form-control-lg form-control-a" id="Type">
								<option>Idea 1</option>
								<option>Idea 2</option>
								<option>Idea 3</option>
								<option>Idea n</option>
							</select>
						</div>
					</div>
					<div class="col-md-6 mb-2">
						<div class="form-group">
							<label for="city">Destinazione</label>
							<select class="form-control form-control-lg form-control-a" id="city">
								<option>Destinazione 1</option>
								<option>Destinazione 2</option>
								<option>Destinazione 3</option>
								<option>Destinazione 4</option>
								<option>Destinazione n</option>
							</select>
						</div>
					</div>
					<div class="col-md-6 mb-2">
						<div class="form-group">
							<label for="bathrooms">Offerte</label>
							<select class="form-control form-control-lg form-control-a" id="bathrooms">
								<option>Offerta 1</option>
								<option>Offerta 2</option>
								<option>Offerta 3</option>
								<option>Offerta 4</option>
							</select>
						</div>
					</div>
					<div class="col-md-6 mb-2">
						<div class="form-group">
							<label for="price">Prezzo massimo</label>
							<select class="form-control form-control-lg form-control-a" id="price">
								<option>Senza limite</option>
								<option>€5.000</option>
								<option>€10.000</option>
								<option>€15.000</option>
								<option>€20.000</option>
							</select>
						</div>
					</div>
					<div class="col-md-12">
						<button type="submit" class="btn btn-b">Cerca</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>

<ng-template #mySignin let-modal>
	<nr-login [slides]="slides"></nr-login>
</ng-template>
