import { NgModule }                  from '@angular/core';
import {
	RouterModule,
	Routes
}                                    from '@angular/router';
import { HomepageComponent }         from './components/homepage/homepage.component';
import { DestinationspageComponent } from './components/destinationspage/destinationspage.component';
import { DestinationpageComponent }  from './components/destinationpage/destinationpage.component';
import { IdeaspageComponent  }       from './components/ideaspage/ideaspage.component';
import { OfferspageComponent }       from './components/offerspage/offerspage.component';
import { ContactspageComponent }     from './components/contactspage/contactspage.component';
import { PartnerspageComponent }     from './components/partnerspage/partnerspage.component';
import { AboutpageComponent }        from './components/aboutpage/aboutpage.component';
import { IdeapageComponent }         from './components/ideapage/ideapage.component';
import { TrippageComponent }         from './components/trippage/trippage.component';
import { ArticlepageComponent } 	 from './components/articlepage/articlepage.component';
import { OpendestpageComponent } 	 from './components/opendestpage/opendestpage.component';
import { OpendestpageDetailComponent } from './components/opendestpage-detail/opendestpage-detail.component';

const routes: Routes = [
	{ path: '',                            component: HomepageComponent,           resolve: {} },
	// { path: ':ln',                        component: HomepageComponent,            resolve: {} },
	{ path: 'home/:ln',                    component: HomepageComponent,           resolve: {} },
	{ path: 'destinations',                component: DestinationspageComponent,   resolve: {} },
	{ path: 'destinations/:id',            component: DestinationpageComponent,    resolve: {} },
	{ path: 'ideas',                       component: IdeaspageComponent,          resolve: {} },
	{ path: 'ideas/:id',                   component: IdeapageComponent,           resolve: {} },
	{ path: 'offers',                      component: OfferspageComponent,         resolve: {} },
	{ path: 'contacts',                    component: ContactspageComponent,       resolve: {} },
	{ path: 'partners',                    component: PartnerspageComponent,       resolve: {} },
	{ path: 'about',                       component: AboutpageComponent,          resolve: {} },
	{ path: 'trips/:id',                   component: TrippageComponent,           resolve: {} },
	{ path: 'services/:id',                component: TrippageComponent,           resolve: {} },
	{ path: 'article/:id',                 component: ArticlepageComponent,        resolve: {} },
	{ path: 'opendestination',             component: OpendestpageComponent,       resolve: {} },
	{ path: 'opendestpagedetail/:id/:ido', component: OpendestpageDetailComponent, resolve: {} }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		scrollPositionRestoration: 'enabled',
		relativeLinkResolution: 'legacy'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
