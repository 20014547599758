import { Injectable, NgZone } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { GoogleMapsAPIWrapper } from './../google-maps-api-wrapper';
import { NrMarker } from '../../directives/nr-marker';

@Injectable()
export class MarkerManagerService {
    protected _markers: Map<NrMarker, Promise<google.maps.marker.AdvancedMarkerElement>> = new Map<NrMarker, Promise<google.maps.marker.AdvancedMarkerElement>>();
    
    constructor(protected _mapsWrapper: GoogleMapsAPIWrapper, protected _zone: NgZone) { }

    async convertAnimation(uiAnim: keyof typeof google.maps.Animation | null) {
        if (uiAnim === null) {
            return null;
        } else {
            return this._mapsWrapper.getNativeMap().then(() => google.maps.Animation[uiAnim]);
        }
    }

    deleteMarker(markerDirective: NrMarker): Promise<void> {
        const markerPromise = this._markers.get(markerDirective);
        if (markerPromise == null) {
            // marker already deleted
            return Promise.resolve();
        }
        return markerPromise.then((marker: google.maps.marker.AdvancedMarkerElement) => {
            return this._zone.run(() => {
                marker.map=null;
                this._markers.delete(markerDirective);
            });
        });
    }

    updateMarkerPosition(marker: NrMarker): Promise<void> {
        return this._markers.get(marker).then(
            (m: google.maps.marker.AdvancedMarkerElement) =>{m.position={ lat: marker.latitude, lng: marker.longitude }});
    }

    updateTitle(marker: NrMarker): Promise<void> {
        return this._markers.get(marker).then((m: google.maps.marker.AdvancedMarkerElement) => {m.title=marker.title});
    }

    updateLabelAndOpacityAndIconAndAnimation(marker: NrMarker): Promise<void> {
        return this._markers.get(marker).then((m: google.maps.marker.AdvancedMarkerElement) => { m.content = marker.element.nativeElement;});
    }

    updateDraggable(marker: NrMarker): Promise<void> {
        return this._markers.get(marker).then((m: google.maps.marker.AdvancedMarkerElement) => {m.gmpDraggable=marker.draggable});
    }


    updateOpacity(marker: NrMarker): Promise<void> {
        return this._markers.get(marker).then((m: google.maps.marker.AdvancedMarkerElement) => {m.style.opacity=marker.opacity.toString()});
    }

    updateVisible(marker: NrMarker): Promise<void> {
        return this._markers.get(marker).then((m: google.maps.marker.AdvancedMarkerElement) => {m.map=marker.opacity?m.map:null});
    }

    updateZIndex(marker: NrMarker): Promise<void> {
        return this._markers.get(marker).then((m: google.maps.marker.AdvancedMarkerElement) => {m.zIndex=marker.zIndex});
    }

    updateClickable(marker: NrMarker): Promise<void> {
        return this._markers.get(marker).then((m: google.maps.marker.AdvancedMarkerElement) => {m.gmpClickable=marker.clickable});
    }


    async addMarker(marker: NrMarker) {
        
        const markerPromise = new Promise<google.maps.marker.AdvancedMarkerElement>(async (resolve) =>
            this._mapsWrapper.createMarker({
                position: { lat: marker.latitude, lng: marker.longitude },
                content: marker.element.nativeElement,
                gmpDraggable: marker.draggable,
                //icon: marker.iconUrl,
                //opacity: marker.opacity,
                map: marker.visible? await this._mapsWrapper.getNativeMap():null,
                zIndex: marker.zIndex,
                title: marker.title,
                gmpClickable: marker.clickable,
                
                //animation: await this.convertAnimation(marker.animation),
            }).then(resolve));
        this._markers.set(marker, markerPromise);   

    }

    getNativeMarker(marker: NrMarker): Promise<google.maps.marker.AdvancedMarkerElement> {
        return this._markers.get(marker);
    }

    createEventObservable<T extends (google.maps.MapMouseEvent | void)>(
        eventName: string,
        marker: NrMarker): Observable<T> {
        return new Observable(observer => {
            this._markers.get(marker).then(m =>
                m.addListener(eventName, e => this._zone.run(() => observer.next(e)))
            );
        });
    }
}
