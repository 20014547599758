import { Component, OnDestroy, OnInit } from '@angular/core';
import { EOperatore, ETipoEntita, PageData, PageDataGeneric } from 'nr-common';
import { ApiService } from '../../../services/api.service';
import { WebsiteService } from '../../../services/website.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'projects/nr-b2c/src/environments/environment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
	selector: 'nr-base-page',
	templateUrl: './base-page.component.html',
	styleUrls: ['./base-page.component.scss'],
})
export class BasePageComponent implements OnInit, OnDestroy {
	public pageLinkId: number = -1;
	public pageName: string = '';
	public pageData: PageData;
	public footerData: PageData;
	public eTipoEntita = ETipoEntita;
    
	public get isDevelop():boolean {
        return !environment.production;
	}
    
    private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public get isLoading(): Observable<boolean> {
		return this._loading.asObservable();
	}

	public get pageHeader() {
		return this.pageData?.elencoDati[0];
	}

	public get pageDataBody() {
		return this.pageData?.elencoDati?.slice(1);
	}

	public pageHeaderName(capitallizeAllWords: boolean = false) {
		let name = this.pageData?.elencoDati[0]?.name || '';
		if (name == '') return '';

		const arrayName = name.split(' ');
		for (var i = 0; i < arrayName.length; i++) {
			if (arrayName[i].length < 2 && !capitallizeAllWords) continue;
			arrayName[i] = arrayName[i].charAt(0).toUpperCase() + arrayName[i].slice(1);
		}
		return arrayName.join(' ');
	}

	public pageHeaderContent() {
		if (!this.pageData?.elencoDati[0]?.content)
			return '';

		let result: string = this.pageData?.elencoDati[0]?.content || '';

		if (this.isDevelop) {
			const textId: number = this.pageData?.elencoDati[0]?.id || 0;
			const entityType: string = this.pageData?.elencoDati[0]?.entityType.toString() || '';

			result = `<span class="nr-invisible">${textId} - ${entityType}</span>` + result;
		}

		return result;
	}

	public cutThePageDataBodyTail(): PageDataGeneric {
		return this.pageData?.elencoDati?.pop();
	}

    private subscription = new Subscription();

	constructor(
		public apiService: ApiService,
		public webSiteService: WebsiteService,
		public titleService: Title,
		public translateService: TranslateService
	) {}

	ngOnInit(): void {
		if (this.pageName == '')
			// skippo l'inizializzazione per le pagine NON inizializzate
			return;

        // Subscribe to the current operator change
        this.subscription.add(
            this.translateService.onLangChange.subscribe(() => {
                this.loadData();
            })
        );        
        this.loadData();
	}

    private loadData() {
        this.pageLinkId = this.webSiteService.getPageLinkId(this.pageName);
        //console.log("page", this.pageLinkId, this.pageName);

        if (this.pageLinkId > 0) {
            this._loading.next(true);
            this.subscription.add(
                this.apiService.getPageData(this.pageLinkId).subscribe((data: PageData) => {
                    this.pageData = data;
                    this._loading.next(false);
                })
            );
        }

        const siteNameKey: string = this.webSiteService.getValidTranslationKey('site name');
        const pageNameKey: string = this.webSiteService.getPageTitleKey(this.pageName);

        this.subscription.add(this.translateService.get([siteNameKey, pageNameKey]).subscribe((titles) => {
                this.titleService.setTitle(this.webSiteService.formatPageTitle(titles[pageNameKey], titles[siteNameKey]));
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
