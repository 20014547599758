import {Pipe, PipeTransform} from '@angular/core';
import {formatNumber} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

interface Unit {
	/**
	 * Soglia
	 */
	threshold: number;

	/**
	 * Formato (da usare con formatNumber)
	 */
	format: string;

	/**
	 * Unità di misura
	 */
	um: string;
}

interface UnitSet {
	/**
	 * Unità senza multiplo
	 */
	noMult: Unit,

	/**
	 * Migliaia
	 */
	kilo: Unit,

	/**
	 * Milioni
	 */
	mega: Unit,

	/**
	 * Miliardi
	 */
	giga: Unit,
}

const SI: UnitSet = {
	noMult: {threshold: 0, um: 'B', format: '1.0-0'},
	kilo: {threshold: 1000, um: 'KB', format: '1.0-0'},
	mega: {threshold: 1000000, um: 'MB', format: '1.1-1'},
	giga: {threshold: 1000000000, um: 'GB', format: '1.2-2'}
}

const BIN: UnitSet = {
	noMult: {threshold: 0, um: 'B', format: '1.0-0'},
	kilo: {threshold: 1024, um: 'KiB', format: '1.0-0'},
	mega: {threshold: 1048576, um: 'MiB', format: '1.1-1'},
	giga: {threshold: 1073741824, um: 'GiB', format: '1.2-2'}
}

@Pipe({
	name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

	constructor(private translate: TranslateService) {
	}

	transform(value: any, ...args: any[]): any {
		const unitSet = args != null && args.length && !!args[0] ? BIN : SI;
		const emptySize = `${formatNumber(0, this.translate.currentLang, unitSet.noMult.format)} ${unitSet.noMult.um}`;

		if (!value)
			return emptySize;
		try {
			const size = +value;
			if (!value)
				return emptySize;
			if (size > unitSet.giga.threshold) {
				const sizeGb = size / unitSet.giga.threshold;
				return `${formatNumber(sizeGb, this.translate.currentLang, unitSet.giga.format)} ${unitSet.giga.um}`;
			}
			if (size > unitSet.mega.threshold) {
				const sizeMb = size / unitSet.mega.threshold;
				return `${formatNumber(sizeMb, this.translate.currentLang, unitSet.mega.format)} ${unitSet.mega.um}`;
			}
			if (size > unitSet.kilo.threshold) {
				const sizeKb = size / unitSet.kilo.threshold;
				return `${formatNumber(sizeKb, this.translate.currentLang, unitSet.kilo.format)} ${unitSet.kilo.um}`;
			}
			return `${formatNumber(size, this.translate.currentLang, unitSet.noMult.format)} ${unitSet.noMult.um}`;
		} catch (e) {
			return emptySize;
		}
	}
}
