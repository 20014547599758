<section class="intro-single">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-lg-9">
				<div class="title-single-box">
					<h1 class="title-single">{{ tripInfo?.nome }}</h1>
				</div>
			</div>
			<div class="col-md-12 col-lg-3 p-0 b-col">
				<div class="day-night"
					*ngIf="canShowDays()">
					<div class="day">
						<span><i class="fas fa-sun"></i> {{ (tripInfo)?.itinerario.giorni }}</span>
						<span>{{ 'PAGES.TRIP.NUM-GIORNI' | translate : { gg:((tripInfo)?.itinerario.giorni) } }}</span>
					</div>
					<div class="night">
						<span><i class="fas fa-moon"></i> {{ (tripInfo)?.itinerario.notti }}</span>
						<span>{{ 'PAGES.TRIP.NUM-NOTTI' | translate : { nn:((tripInfo)?.itinerario.notti) } }}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-12 col-lg-9">
				<div id="gallery" *ngIf="slides.length > 0">
					<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
						<div ngxSlickItem *ngFor="let slide of slides" class="slide">
							<img src="{{ slide.img }}" alt="" width="100%">
						</div>
					</ngx-slick-carousel>
				</div>

				<nr-tappe [tappe]="tripInfo?.itinerario?.days"></nr-tappe>

				<article class="text-description" [innerHtml]="tripInfo?.descr"></article>

				<article class="translation-container">
					<ng-container *ngFor="let item of tripInfo?.articoli">
						<h6 *ngIf="item.titolo" [innerHtml]="item.titolo"></h6>
						<div *ngIf="item.testo" class="text-description" [innerHtml]="item.testo"></div>
					</ng-container>
				</article>

				<section class="translation-container">
					<ng-container *ngFor="let item of tripInfo?.articoliTraducibili">
						<h6 *ngIf="item.titolo" [innerHtml]="item.titolo"></h6>
						<div *ngIf="item.testo" class="text-description" [innerHtml]="item.testo"></div>
					</ng-container>
				</section>
			</div>

			<div class="col-md-12 col-lg-3 p-0 gray-col">
				<nr-mappa *ngIf="hasMap()"
					[mappa]="mappa"
					[tappe]="tripInfo?.itinerario.days"></nr-mappa>
				<!-- <nr-destinations-list></nr-destinations-list> -->

				<section class="attachments" *ngIf="hasAttachments()">
					<h6>{{ 'PAGES.TRIP.MAGGIORI-INFO' | translate }}</h6>
					<div *ngFor="let allegato of tripInfo?.allegati">
						<a [href]="getAttachmentUrl(allegato.fileUrl)" target="_blank">{{allegato.descr}}</a>
					</div>
				</section>
				<section class="trip-data p-3">
					<nr-attributi [attributi]="tripInfo?.attributi"></nr-attributi>

					<div class="mb-3" *ngIf="hasDestinations()">
						<h5>{{ 'PAGES.TRIP.APPARTIENE-ANCHE' | translate }}</h5>
						<nr-destinations-list [destinations]="destinations"></nr-destinations-list>
					</div>

					<div class="mb-3" *ngIf="hasIdeas()">
						<h5>{{ 'PAGES.TRIP.APPARTIENE-ANCHE' | translate }}</h5>
						<nr-ideas-list [ideas]="ideas"></nr-ideas-list>
					</div>
				</section>
			</div>
		</div>
	</div>
</section>
