import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Banner, EBannerType } from 'nr-common';

import { environment } from 'projects/nr-b2b/src/environments/environment';
import { CommonService } from '../../../services/common.service';
import { UrlService } from '../../../services/url.service';

const MAX_DESCR_LEN = 200;
const DEFAULT_BACKGROUND_COLOR = '#707479'; // '#343a40b3';

@Component({
	selector: 'nr-banner-card',
	templateUrl: './banner-card.component.html',
	styleUrls: ['./banner-card.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class BannerCardComponent implements OnInit {
	get invertedColor(): string {
        if (this.banner.destination?.gaBgColor == null) return null;
		return this.commonService.invertColor(this.banner.destination.gaBgColor, this.commonService.INVERT_ONLY_BLACK_AND_WHITE);
	}
	@Input() banner: Banner;
	public eBannerType = EBannerType;

	constructor(
		public translate: TranslateService,
		public urlService: UrlService,
		public commonService: CommonService) {}

	ngOnInit(): void {}

	endSoftly(v: string): string {
		if (v && v.length > MAX_DESCR_LEN) {
			v = v.substring(0, MAX_DESCR_LEN);
			const lastBlank = v.lastIndexOf(' ');
			v = v.substring(0, lastBlank) + '...';
		}
		return v;
	}

	getIcon(iconGliph: string): string {
        if (iconGliph == null) return null;
		if (!iconGliph) {
			iconGliph = 'icon-people';
		}
		return iconGliph;
	}

	getColor(color: string): string {
		if (!color) return this.invertedColor; // this.commonService.invertColor(DEFAULT_BACKGROUND_COLOR, this.commonService.INVERT_ONLY_BLACK_AND_WHITE);
		if (!color.startsWith('#')) {
			color = '#' + color;
		}
		return this.commonService.invertColor(color, this.commonService.INVERT_ONLY_BLACK_AND_WHITE);
	}

	getBgColor(bgColor: string): string {
		if (!bgColor) return DEFAULT_BACKGROUND_COLOR;
		if (!bgColor.startsWith('#')) {
			bgColor = '#' + bgColor;
		}
		return bgColor;
	}

	transformName(name: string): string {
		return this.commonService.capitalizeAllWords(name);
	}

	getSunto(sunto: string): string {
		/* Qui vengono modificati alcuni valori in sviluppo per testare i download */
		// if (!environment.production) {
		// 	sunto = sunto.replace('https://api.naar.com/', 'http://localhost:4201/');
		// 	console.log(`id:${this.banner.id}, EP:${environment.servicesEndpointRoot}, Sunto:${sunto}, Result:${result}`);
		// }

		/*	infilo un target="_blank" per ogni anchor nel sunto; forzando di fatto l'apertura dei link del banner in una nuova pagina (sempre) */
		sunto = sunto.replace('<a ', '<a target="_blank" ');
		return sunto;
	}

	hasRibbon(banner: Banner): boolean {
		return banner.bannerType == EBannerType.Banner && banner.attachment != null;
	}

	getRibbonType(banner: Banner): string {
		if (banner.bannerType != EBannerType.Banner)
			// taglio se non è banner
			return '';

		/**
		 * Nel caso fosse un banner
		 *	potrebbe avere un allegato => pagina di download allegati
		 *	senza allegato è un link annegato nel testo.
		 */
		let result: string = '';

		if (banner.attachment) {
			const aId: number = banner.attachmentId;
			const attachmentFileType = banner.attachmentFileType.substring(1);
			const attachmentRoot: string = environment.servicesEndpointRoot;
			// result = `${attachmentRoot}articles/get/${banner.id}/attachment/${aId}`
			if (attachmentFileType != null) {
				result = attachmentFileType.toUpperCase(); //`assets/img/special-deal.svg?t=${attachmentFileType}`;
			} else {
				result = '???'; // "assets/img/special-deal.png";
			}
		}

		return result;
	}
}
