<ng-template #defaultTemplate>
	<input type="checkbox" name="icb"
		[ngClass]="nrClass"
		[ngModel]="selectableItem.selected" 
		(ngModelChange)="changeSelection($event)"/>
</ng-template>
<ng-template 
	[ngTemplateOutlet]="customTemplate || defaultTemplate" 
	[ngTemplateOutletContext]="context">
</ng-template>
