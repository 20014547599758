import { AfterContentInit, Component, ContentChildren, Directive, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChange, SimpleChanges, forwardRef } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';

import { MarkerManagerService } from '../services/managers/marker-manager.service';
import { NrInfoWindow } from './nr-info-window';
import { FitBoundsAccessor, FitBoundsDetails } from '../services/fit-bounds.service';
import { GoogleMapsAPIWrapper } from '../services';

let markerId = 0;

export type NrMapsMarkerIcon = string | google.maps.Icon | google.maps.Symbol;
@Component({
    selector: 'nr-maps-marker',
    template: `
    <div 
    (pointerover)="this.mouseOver.emit()"
    (pointerout)="this.mouseOut.emit()"
    (contextmenu)="this.markerRightClick.emit()"
    (dblclick)="this.markerDblClick.emit()"
    [ngClass]='this.animation' [title]="title" style="opacity: {{this.opacity}};">{{this.label}}
      <img [src]="getIconUrl()" />
    </div>
    `,
    styles: [`
    @keyframes drop {
  0% {
    transform: translateY(-200px) scaleY(0.9);
    opacity: 0;
  }
  5% {
    opacity: 0.7;
  }
  50% {
    transform: translateY(0px) scaleY(1);
    opacity: 1;
  }
  65% {
    transform: translateY(-17px) scaleY(0.9);
    opacity: 1;
  }
  75% {
    transform: translateY(-22px) scaleY(0.9);
    opacity: 1;
  }
  100% {
    transform: translateY(0px) scaleY(1);
    opacity: 1;
  }
}
.drop {
  animation: drop .3s linear forwards;
}`],
    providers: [
        { provide: FitBoundsAccessor, useExisting: forwardRef(() => NrMarker) },
    ],
})
export class NrMarker implements OnDestroy, OnChanges, AfterContentInit{
    /**
     * The latitude position of the marker.
     */
    @Input() latitude: number;

    /**
     * The longitude position of the marker.
     */
    @Input() longitude: number;

    /**
     * The title of the marker.
     */
    @Input() title: string = "";

    /**
     * The label (a single uppercase character) for the marker.
     */
    @Input() label: string | google.maps.MarkerLabel;

    /**
     * If true, the marker can be dragged. Default value is false.
     */
    // tslint:disable-next-line:no-input-rename
    @Input('markerDraggable') draggable = false;

    /**
     * Icon (the URL of the image) for the foreground.
     */
    @Input() iconUrl: NrMapsMarkerIcon;

    public getIconUrl(){
      if(!this.iconUrl){
        console.warn("iconUrl of marker not defined");
        return null;
      }
      else if(typeof this.iconUrl == 'string'){
        return  this.iconUrl;
      }
      else if("url" in this.iconUrl){
        return this.iconUrl.url;
      }
      else if("path" in this.iconUrl){
        return this.iconUrl.path;
      }
      else{
        return this.iconUrl;
      }
    }
    /**
     * If true, the marker is visible
     */
    @Input() visible = true;

    /**
     * Whether to automatically open the child info window when the marker is clicked.
     */
    @Input() openInfoWindow = true;

    /**
     * The marker's opacity between 0.0 and 1.0.
     */
    @Input() opacity = 1;

    /**
     * All markers are displayed on the map in order of their zIndex, with higher values displaying in
     * front of markers with lower values. By default, markers are displayed according to their
     * vertical position on screen, with lower markers appearing in front of markers further up the
     * screen.
     */
    @Input() zIndex = 1;

    /**
     * If true, the marker can be clicked. Default value is true.
     */
    // tslint:disable-next-line:no-input-rename
    @Input('markerClickable') clickable = true;

    /**
     * Which animation to play when marker is added to a map.
     */
    @Input() animation: string;

    /**
     * This event is fired when the marker's animation property changes.
     */
    @Output() animationChange = new EventEmitter<string>();

    /**
     * This event emitter gets emitted when the user clicks on the marker.
     */
    @Output() markerClick: EventEmitter<NrMarker> = new EventEmitter<NrMarker>();

    /**
     * This event emitter gets emitted when the user clicks twice on the marker.
     */
    @Output() markerDblClick: EventEmitter<NrMarker> = new EventEmitter<NrMarker>();

    /**
     * This event is fired when the user rightclicks on the marker.
     */
    @Output() markerRightClick: EventEmitter<void> = new EventEmitter<void>();

    /**
     * This event is fired when the user starts dragging the marker.
     */
    @Output() dragStart: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter<google.maps.MapMouseEvent>();

    /**
     * This event is repeatedly fired while the user drags the marker.
     */
    // tslint:disable-next-line: no-output-native
    @Output() drag: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter<google.maps.MapMouseEvent>();

    /**
     * This event is fired when the user stops dragging the marker.
     */
    @Output() dragEnd: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter<google.maps.MapMouseEvent>();

    /**
     * This event is fired when the user mouses over the marker.
     */
    @Output() mouseOver: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter<google.maps.MapMouseEvent>();

    /**
     * This event is fired when the user mouses outside the marker.
     */
    @Output() mouseOut: EventEmitter<google.maps.MapMouseEvent> = new EventEmitter<google.maps.MapMouseEvent>();

    /** @internal */
    @ContentChildren(NrInfoWindow) infoWindow: QueryList<NrInfoWindow> = new QueryList<NrInfoWindow>();

    private _markerAddedToManger = false;
    private _id: string;
    private _observableSubscriptions: Subscription[] = [];
    advancedMarker: google.maps.marker.AdvancedMarkerElement;

    protected readonly _fitBoundsDetails$: ReplaySubject<FitBoundsDetails> = new ReplaySubject<FitBoundsDetails>(1);

    constructor(private _markerManager: MarkerManagerService, public element: ElementRef, private _ngZone:NgZone, private wrapper :GoogleMapsAPIWrapper) { this._id = (markerId++).toString(); }
    
/* @internal */
    ngAfterContentInit() {  
        this.handleInfoWindowUpdate();
        this.infoWindow.changes.subscribe(() => this.handleInfoWindowUpdate());
    }

    private handleInfoWindowUpdate() {
        if (this.infoWindow.length > 1) {
            throw new Error('Expected no more than one info window.');
        }
        this.infoWindow.forEach(marker => {
            marker.hostMarker = this;
        });
    }

/** @internal */
    ngOnChanges(changes: { [key: string]: SimpleChange }) {
        if (typeof this.latitude === 'string') {
            this.latitude = Number(this.latitude);
        }
        if (typeof this.longitude === 'string') {
            this.longitude = Number(this.longitude);
        }
        if (typeof this.latitude !== 'number' || typeof this.longitude !== 'number') {
            return;
        }
        if (!this._markerAddedToManger) {
            this._markerManager.addMarker(this);
            this._updateFitBoundsDetails();
            this._markerAddedToManger = true;
            this._addEventListeners();
            return;
        }
        
        if (changes['latitude'] || changes['longitude']) {
            this._markerManager.updateMarkerPosition(this);
            this._updateFitBoundsDetails();
        }
        if (changes['title']) {
            this._markerManager.updateTitle(this);
        }
        if (changes['draggable']) {
            this._markerManager.updateDraggable(this);
        }
        if (changes['label']||changes['iconUrl']||changes['opacity']||changes['animation']) {
            this._markerManager.updateLabelAndOpacityAndIconAndAnimation(this);
        }
        if (changes['opacity']) {
            this._markerManager.updateOpacity(this);
        }
        if (changes['visible']) {
            this._markerManager.updateVisible(this);
        }
        if (changes['zIndex']) {
            this._markerManager.updateZIndex(this);
        }
        if (changes['clickable']) {
            this._markerManager.updateClickable(this);
        }
    }

/** @internal */
    getFitBoundsDetails$(): Observable < FitBoundsDetails > {
        return this._fitBoundsDetails$.asObservable();
    }

    protected _updateFitBoundsDetails() {
        this._fitBoundsDetails$.next({ latLng: { lat: this.latitude, lng: this.longitude } });
    }

    private _addEventListeners() {        
        const cs = this._markerManager.createEventObservable('click', this).subscribe(() => {
            if (this.openInfoWindow) {
                this.infoWindow.forEach(infoWindow => infoWindow.open());
            }
            this.markerClick.emit(this);
        });
        this._observableSubscriptions.push(cs);

/*         const dcs = this._markerManager.createEventObservable('dblclick', this).subscribe(() => {
            this.markerDblClick.emit(null);
        });
        this._observableSubscriptions.push(dcs);

        const rc = this._markerManager.createEventObservable('rightclick', this).subscribe(() => {
            this.markerRightClick.emit(null);
        });
        this._observableSubscriptions.push(rc); */

        const ds =this._markerManager.createEventObservable<google.maps.MapMouseEvent>('dragstart', this)
                .subscribe(e => this.dragStart.emit(e));
        this._observableSubscriptions.push(ds);

        const d =
            this._markerManager.createEventObservable<google.maps.MapMouseEvent>('drag', this)
                .subscribe(e => this.drag.emit(e));
        this._observableSubscriptions.push(d);

        const de =
            this._markerManager.createEventObservable<google.maps.MapMouseEvent>('dragend', this)
                .subscribe(e => this.dragEnd.emit(e));
        this._observableSubscriptions.push(de);

/*         const mover =
            this._markerManager.createEventObservable<google.maps.MapMouseEvent>('mouseover', this)
                .subscribe(e => { console.log("mouse over"); this.mouseOver.emit(e); });
        this._observableSubscriptions.push(mover);

        const mout =
            this._markerManager.createEventObservable<google.maps.MapMouseEvent>('mouseout', this)
                .subscribe(e => this.mouseOut.emit(e));
        this._observableSubscriptions.push(mout);
 */
        const anChng =
            this._markerManager.createEventObservable<void>('animation_changed', this)
                .subscribe(() => {
                    this.animationChange.emit(this.animation);
                });
        this._observableSubscriptions.push(anChng);
    }

/** @internal */
    id(): string { return this._id; }

/** @internal */
    toString(): string { return 'NrMapsMarker-' + this._id.toString(); }

/** @internal */
    ngOnDestroy() {
        this._markerManager.deleteMarker(this);
        // unsubscribe all registered observable subscriptions
        this._observableSubscriptions.forEach((s) => s.unsubscribe());
    } 

}
