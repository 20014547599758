import {Banner, TripHead} from 'nr-common';

export class TripCard {

	id: number;
	imgUrl: string;
	title: string;
	abstract: string;
	price: number;
	language = '';
	days: number;
	nights: number;

	completeByBanner(banner: Banner) {
		this.id = banner.id;
		this.imgUrl = banner.imgUrl;
		this.title = banner.title;
		this.abstract = banner.sunto;
		this.days = 0;
		this.nights = 0;

		this.price = 5000 + (Math.random() * 10000);
	}

	completeByTrip(trip: TripHead) {
		this.id = trip.tripID;
		this.imgUrl = trip.cover;
		this.title = trip.nome;
		this.abstract = trip.descr;
		this.language = trip.lingua;
		this.days = trip.durata.giorni;
		this.nights = trip.durata.notti;

		this.price = 5000 + (Math.random() * 10000);
	}
}
