import {
	Component,
	Inject,
	OnInit,
	ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
	Destination,
	Idea,
	Mappa,
	TripInfo,
	TripItineraryDay,
	Map,
    UserService
} from 'nr-common';
import { ApiService }         from '../../services/api.service';
import { UrlService }         from '../../services/url.service';
import { TripService }        from '../../services/trip.service';
import { DestinationService } from '../../services/destination.service';
import { IdeaService } from '../../services/idea.service';
import { SherpaService } from '../../services/sherpa.service';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
	selector: 'nr-trippage',
	templateUrl: './trippage.component.html',
	styleUrls: ['./trippage.component.scss'],
	encapsulation: ViewEncapsulation.None	// https://angular.io/guide/view-encapsulation
})
export class TrippageComponent implements OnInit {
	public tripInfo: TripInfo;
	public destinations: Destination[] = [];
	public ideas: Idea[] = [];
	public mappa: Mappa;


	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: '<div class="nav-btn next-slide"><i class="fa fa-chevron-right"></i></div>',
		prevArrow: '<div class="nav-btn prev-slide"><i class="fa fa-chevron-left"></i></div>',
		dots: true,
		infinite: true,
		swipe: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	slides = [];

	constructor(
		private apiService: ApiService,
		private activeRoute: ActivatedRoute,
		private tripService: TripService,
		public urlService: UrlService,
		public translate: TranslateService,
		private destinationService: DestinationService,
		private ideaService: IdeaService,
		private sherpa: SherpaService,
        private titleService: Title,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private user: UserService,
        private google: GoogleAnalyticsService
	) {
		this.mappa = new Mappa();
	}

	ngOnInit(): void {
		this.activeRoute
			.params
			.subscribe(routeParams => {
				this.tripService
					.getTripInfo(routeParams.id)
					.subscribe((data) => {
						this.tripInfo = data;

                        // OM 08.06.2023: update page title
                        this.titleService.setTitle(this.tripInfo.nome);

                        // OM 08.06.2023: Google Analytics
                        this.google.tag(this.tripInfo.nome,
                                        this.router.url,
                                        this.document.location.href);

						this.loadSlides();
						this.loadDestination(this.tripInfo.destinations);
						this.loadIdeas(this.tripInfo.ideas);
						this.mappa = this.getMap(data);	// this.mappa = this.getMapFromItinerary(this.tripInfo.itinerario.days);
						this.sherpa.addTripOfInterestByCompleteTrip(this.tripInfo);
					});
			});
	}

	getMapFromItinerary(giorniItinerario: TripItineraryDay[]): Mappa {
		const result: Mappa = new Mappa();
		result.latitudine = 0;
		result.longitudine = 0;
		result.zoom = 3;


		if (giorniItinerario && giorniItinerario.length > 0) {
			result.latitudine = giorniItinerario[0].lat;
			result.longitudine = giorniItinerario[0].lon;
			result.zoom = 6;
		}
		return result;
	}

	getMap(trip: TripInfo): Mappa {
		if (!trip) return null;

		const result: Mappa = new Mappa();
		result.latitudine  = trip.map?.lat;
		result.longitudine = trip.map?.long;
		result.zoom = trip.map?.zoom;

		// console.log(result);
		return result;
	}

	loadDestination(destinationIds: number[]): void {
		if (!(destinationIds?.length > 0)) return;
		destinationIds.forEach(f => {
			this.destinationService.getDestinationInfo(f).subscribe(destination => {
				this.destinations.push(destination);
			});
		})
	}

	loadIdeas(ideaIds: number[]) {
		if (!(ideaIds?.length > 0)) return;
		ideaIds.forEach(f => {
			this.ideaService.getIdeaInfo(f).subscribe(idea => {
				this.ideas.push(idea);
			});
		})
	}

	/**
	 * Popolo la lista delle immagini della destinazione per il carousel
	 * @returns void
	 */
	loadSlides(): void {
		if (this.tripInfo.immagini?.length > 0) {
			this.tripInfo.immagini
				.forEach(immagine => {
					this.slides.push({
						img: immagine.url
					}
					)
				});
		} else {
			// Se non ci sono immagini tento almeno di recuperare la cover del viaggio
			this.slides.push({
				img: this.tripInfo.cover
			});
		}
	}

	onMapReady(map?: google.maps.Map) {
		if (map) {
			map.setOptions({
				streetViewControl: false
			});
		}
	}

	canShowDays(): boolean {
		return (this.tripInfo?.itinerario?.giorni || this.tripInfo?.itinerario?.notti) > 0;
	}

	hasDestinations(): boolean {
		return (this.tripInfo?.destinations?.length > 0);
	}

	hasIdeas(): boolean {
		return (this.tripInfo?.ideas?.length > 0);
	}

	hasAttachments(): boolean {
		return this.tripInfo?.allegati?.length > 0;
		// let numOfAttachments: number = 0;
		// this.tripInfo?.articoli?.forEach(article => {
		// 	numOfAttachments += article.attachment?.length || 0
		// })
		// return numOfAttachments > 0;
	}

	getAttachmentUrl(relativeUrl: string): string {
		return relativeUrl;
	}

	hasMap(): boolean {
		return this.mappa != null;
	}
}

/**
https://angular-maps.com/guides/getting-started/
 */
