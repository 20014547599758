import {AbstractControl, ValidationErrors, AsyncValidator, NG_ASYNC_VALIDATORS} from '@angular/forms';
import {Directive} from '@angular/core';
import {Observable} from 'rxjs';
import {BackendService} from '../../backend/backend.service';

@Directive({
	selector: '[nr-trip-exists]',
	providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: NrTripExistsValidatorDirective, multi: true}]
})
export class NrTripExistsValidatorDirective implements AsyncValidator {

	constructor(private backend: BackendService) {
	}

	registerOnValidatorChange(fn: () => void): void {
	}

	validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null>;
	validate(control: AbstractControl): ValidationErrors | null;
	validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> | ValidationErrors | null {
		if (control.value || control.value === 0) {
			const request = {offertaID: +control.value};
			return this
				.backend
				.post('validators/trip/exists', request)
				.then(response => {
					if (!response.exists) {
						return {
							tripExists: true
						};
					} else {
						return {};
					}
				});
		}
		return Promise.resolve({});
	}
}
