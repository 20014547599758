export class NrDropdownItem {
	constructor(
		public id: string,
		public label: string,
		public extraInt: number,
		public extraString: string,
		public extraString2: string,
		public extraDecimal: number,
		public extraBool: boolean,
		public inactive: boolean,
		public deleted: boolean,
		public qualifier: string) {
	}
}
