import { Injectable, NgZone } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import { GoogleMapsAPIWrapper } from '../google-maps-api-wrapper';
import { MarkerManagerService } from './marker-manager.service';
import { NrInfoWindow } from '../../directives/nr-info-window';

@Injectable()
export class InfoWindowManagerService {
    private _infoWindows: Map<NrInfoWindow, Promise<google.maps.InfoWindow>> =
        new Map<NrInfoWindow, Promise<google.maps.InfoWindow>>();

    constructor(
        private _mapsWrapper: GoogleMapsAPIWrapper, private _zone: NgZone,
        private _markerManager: MarkerManagerService) { }

    deleteInfoWindow(infoWindow: NrInfoWindow): Promise<void> {
        const iWindow = this._infoWindows.get(infoWindow);
        if (iWindow == null) {
            // info window already deleted
            return Promise.resolve();
        }
        return iWindow.then((i: google.maps.InfoWindow) => {
            return this._zone.run(() => {
                i.close();
                this._infoWindows.delete(infoWindow);
            });
        });
    }

    setPosition(infoWindow: NrInfoWindow): Promise<void> {
        return this._infoWindows.get(infoWindow).then((i: google.maps.InfoWindow) => i.setPosition({
            lat: infoWindow.latitude,
            lng: infoWindow.longitude,
        }));
    }

    setZIndex(infoWindow: NrInfoWindow): Promise<void> {
        return this._infoWindows.get(infoWindow)
            .then((i: google.maps.InfoWindow) => i.setZIndex(infoWindow.zIndex));
    }

    open(infoWindow: NrInfoWindow): Promise<void> {
        return this._infoWindows.get(infoWindow).then((w) => {
            if (infoWindow.hostMarker != null) {
                return this._markerManager.getNativeMarker(infoWindow.hostMarker).then((marker) => {
                    return this._mapsWrapper.getNativeMap().then((map) => w.open(map, marker));
                });
            }
            return this._mapsWrapper.getNativeMap().then((map) => w.open(map));
        });
    }

    close(infoWindow: NrInfoWindow): Promise<void> {
        return this._infoWindows.get(infoWindow).then((w) => w.close());
    }

    setOptions(infoWindow: NrInfoWindow, options: google.maps.InfoWindowOptions) {
        return this._infoWindows.get(infoWindow).then((i: google.maps.InfoWindow) => i.setOptions(options));
    }

    addInfoWindow(infoWindow: NrInfoWindow) {
        const options: google.maps.InfoWindowOptions = {
            content: infoWindow.content,
            maxWidth: infoWindow.maxWidth,
            zIndex: infoWindow.zIndex,
            disableAutoPan: infoWindow.disableAutoPan,
        };
        if (typeof infoWindow.latitude === 'number' && typeof infoWindow.longitude === 'number') {
            options.position = { lat: infoWindow.latitude, lng: infoWindow.longitude };
        }
        const infoWindowPromise = this._mapsWrapper.createInfoWindow(options);
        this._infoWindows.set(infoWindow, infoWindowPromise);
    }

    /**
     * Creates a Google Maps event listener for the given InfoWindow as an Observable
     */
    createEventObservable<T>(eventName: string, infoWindow: NrInfoWindow): Observable<T> {
        return new Observable((observer: Observer<T>) => {
            this._infoWindows.get(infoWindow).then((i: google.maps.InfoWindow) => {
                i.addListener(eventName, (e: T) => this._zone.run(() => observer.next(e)));
            });
        });
    }
}
