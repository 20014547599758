import {Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {TranslationWidth} from '@angular/common';

@Injectable()
export class ItNgbDatepickerI18n extends NgbDatepickerI18n {

	constructor(private translate: TranslateService) {
		super();
	}

	getDayAriaLabel(date: NgbDateStruct): string {
		return '';
	}

	getMonthFullName(month: number, year?: number): string {
		switch (month) {
			case 1:
				return this.translate.instant('CALENDARIO.MESI_COMPLETI.GENNAIO');
			case 2:
				return this.translate.instant('CALENDARIO.MESI_COMPLETI.FEBBRAIO');
			case 3:
				return this.translate.instant('CALENDARIO.MESI_COMPLETI.MARZO');
			case 4:
				return this.translate.instant('CALENDARIO.MESI_COMPLETI.APRILE');
			case 5:
				return this.translate.instant('CALENDARIO.MESI_COMPLETI.MAGGIO');
			case 6:
				return this.translate.instant('CALENDARIO.MESI_COMPLETI.GIUGNO');
			case 7:
				return this.translate.instant('CALENDARIO.MESI_COMPLETI.LUGLIO');
			case 8:
				return this.translate.instant('CALENDARIO.MESI_COMPLETI.AGOSTO');
			case 9:
				return this.translate.instant('CALENDARIO.MESI_COMPLETI.SETTEMBRE');
			case 10:
				return this.translate.instant('CALENDARIO.MESI_COMPLETI.OTTOBRE');
			case 11:
				return this.translate.instant('CALENDARIO.MESI_COMPLETI.NOVEMBRE');
			case 12:
				return this.translate.instant('CALENDARIO.MESI_COMPLETI.DICEMBRE');
		}
		return '';
	}

	getMonthShortName(month: number, year?: number): string {
		switch (month) {
			case 1:
				return this.translate.instant('CALENDARIO.MESI_ABBREVIATI.GENNAIO');
			case 2:
				return this.translate.instant('CALENDARIO.MESI_ABBREVIATI.FEBBRAIO');
			case 3:
				return this.translate.instant('CALENDARIO.MESI_ABBREVIATI.MARZO');
			case 4:
				return this.translate.instant('CALENDARIO.MESI_ABBREVIATI.APRILE');
			case 5:
				return this.translate.instant('CALENDARIO.MESI_ABBREVIATI.MAGGIO');
			case 6:
				return this.translate.instant('CALENDARIO.MESI_ABBREVIATI.GIUGNO');
			case 7:
				return this.translate.instant('CALENDARIO.MESI_ABBREVIATI.LUGLIO');
			case 8:
				return this.translate.instant('CALENDARIO.MESI_ABBREVIATI.AGOSTO');
			case 9:
				return this.translate.instant('CALENDARIO.MESI_ABBREVIATI.SETTEMBRE');
			case 10:
				return this.translate.instant('CALENDARIO.MESI_ABBREVIATI.OTTOBRE');
			case 11:
				return this.translate.instant('CALENDARIO.MESI_ABBREVIATI.NOVEMBRE');
			case 12:
				return this.translate.instant('CALENDARIO.MESI_ABBREVIATI.DICEMBRE');
		}
		return '';
	}

	getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
		switch (width) {
			case TranslationWidth.Narrow: // L M m G V S D
				switch (weekday) {
					case  1:
						return this.translate.instant('CALENDARIO.GIORNI_INIZIALE.LUNEDI');
					case  2:
						return this.translate.instant('CALENDARIO.GIORNI_INIZIALE.MARTEDI');
					case  3:
						return this.translate.instant('CALENDARIO.GIORNI_INIZIALE.MERCOLEDI');
					case  4:
						return this.translate.instant('CALENDARIO.GIORNI_INIZIALE.GIOVEDI');
					case  5:
						return this.translate.instant('CALENDARIO.GIORNI_INIZIALE.VENERDI');
					case  6:
						return this.translate.instant('CALENDARIO.GIORNI_INIZIALE.SABATO');
					case  7:
						return this.translate.instant('CALENDARIO.GIORNI_INIZIALE.DOMENICA');
				}
				break;

			case TranslationWidth.Wide:	// Lunedì Martedì Mercoledì Giovedì Venerdì Sabato Domenica
				switch (weekday) {
					case  1:
						return this.translate.instant('CALENDARIO.GIORNI_COMPLETI.LUNEDI');
					case  2:
						return this.translate.instant('CALENDARIO.GIORNI_COMPLETI.MARTEDI');
					case  3:
						return this.translate.instant('CALENDARIO.GIORNI_COMPLETI.MERCOLEDI');
					case  4:
						return this.translate.instant('CALENDARIO.GIORNI_COMPLETI.GIOVEDI');
					case  5:
						return this.translate.instant('CALENDARIO.GIORNI_COMPLETI.VENERDI');
					case  6:
						return this.translate.instant('CALENDARIO.GIORNI_COMPLETI.SABATO');
					case  7:
						return this.translate.instant('CALENDARIO.GIORNI_COMPLETI.DOMENICA');
				}
				break;

			case TranslationWidth.Abbreviated: // Lun Mar Mer Gio Ven Sab Dom
				switch (weekday) {
					case  1:
						return this.translate.instant('CALENDARIO.GIORNI_CORTI.LUNEDI');
					case  2:
						return this.translate.instant('CALENDARIO.GIORNI_CORTI.MARTEDI');
					case  3:
						return this.translate.instant('CALENDARIO.GIORNI_CORTI.MERCOLEDI');
					case  4:
						return this.translate.instant('CALENDARIO.GIORNI_CORTI.GIOVEDI');
					case  5:
						return this.translate.instant('CALENDARIO.GIORNI_CORTI.VENERDI');
					case  6:
						return this.translate.instant('CALENDARIO.GIORNI_CORTI.SABATO');
					case  7:
						return this.translate.instant('CALENDARIO.GIORNI_CORTI.DOMENICA');
				}
				break;

			case TranslationWidth.Short: // Lu Ma Me Gi Ve Sa Do
			default:
				switch (weekday) {
					case  1:
						return this.translate.instant('CALENDARIO.GIORNI_ABBREVIATI.LUNEDI');
					case  2:
						return this.translate.instant('CALENDARIO.GIORNI_ABBREVIATI.MARTEDI');
					case  3:
						return this.translate.instant('CALENDARIO.GIORNI_ABBREVIATI.MERCOLEDI');
					case  4:
						return this.translate.instant('CALENDARIO.GIORNI_ABBREVIATI.GIOVEDI');
					case  5:
						return this.translate.instant('CALENDARIO.GIORNI_ABBREVIATI.VENERDI');
					case  6:
						return this.translate.instant('CALENDARIO.GIORNI_ABBREVIATI.SABATO');
					case  7:
						return this.translate.instant('CALENDARIO.GIORNI_ABBREVIATI.DOMENICA');
				}
		}
		return '';
	}
}
