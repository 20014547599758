import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServiceAttribute } from 'nr-common';

@Component({
  selector: 'nr-attributi',
  templateUrl: './attributi.component.html',
  styleUrls: ['./attributi.component.scss']
})
export class AttributiComponent implements OnInit {
	@Input() attributi: ServiceAttribute[];
	constructor(public translate: TranslateService) { }

	ngOnInit() {
	}

	getIconClass(v: number): string {
		let result = '';

		switch (v) {
			case 1: result = 'fa-asterisk'; break;  // Categoria
			case 2: result = 'fa-tag'; break;  // Tipo servizio
			case 3: result = 'fa-globe'; break;  // Posizione
			case 5: result = 'fa-language'; break;  // Lingua
			case 7: result = 'fa-wrench'; break;  // Posizione
			case 11: result = 'fa-info-circle'; break;  // Assistenza
			case 18: result = 'fa-utensils'; break;  // Trattamento
			case 20: result = 'fa-bus'; break;  // Tipo trasferimenti
			case 22: result = 'fa-paper-plane'; break;  // Voli
			case 26: result = 'fa-credit-card'; break;  // Resort Fee
			case 34: result = 'fa-certificate'; break;  // OFFERTE / PROMO
			case 51: result = 'fa-calendar-check'; break;  // Partenze garantite
			case 53: result = 'fa-link'; break;  // Catena
			case 64: result = 'fa-bullhorn'; break;  // NOVITÀ
			case 73: result = 'fa-share'; break;  // Tipo tour
			default: result = 'fa-question-circle'; break;
		}
		return result;
	}

	getExtendedAttribute(v: ServiceAttribute): string {
		if (!v.valori)  return '';

		let l = 0;
		if (v.valori.length === 1) {
			l = v.valori[0].length;
		} else {
			v.valori.forEach((fev) => {
				if (fev.length > l) {
					l = fev.length;
				}
			});
		}
		return l > 100 ? 'attribute-big' : l > 50 ? 'attribute-half' : '';
	}

}

