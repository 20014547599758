<div class="d-inline-block"
	ngbDropdown #userDropDown="ngbDropdown"
	autoClose="outside">

	<a class="nav-link" id="ddLanguages"
		data-toggle="modal"
		data-target="#mySignin"
		title="{{'NAV-BAR.CAMBIA-LINGUA'|translate}}" id="ddLang" ngbDropdownToggle>
		<span [className]="getLanguageClass()" title="{{'NAV-BAR.CAMBIA-LINGUA'|translate}}"></span>
	</a>

	<ul ngbDropdownMenu aria-labelledby="ddLanguages">
		<li class="dropdown-item" *ngFor="let l of getUnselectedLanguages()">
			<a (click)="setUserLanguage(l)">
				<span [className]="getLanguageClass(l)" [title]="getLanguage(l)"></span> &nbsp;
				<span class="user-lang-descr menu-lang" [innerHTML]="getLanguage(l)"></span>
			</a>
		</li>
	</ul>
</div>
