<img class="down-icon" src="assets/img/Download-pdf.png">
<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
	<defs>
		<style>
			.d {
				fill: #fff;
			}

			.e {
				fill: #774611;
			}

			.f {
				isolation: isolate;
			}

			.g {
				fill: #fff;
				font-family: ArialMT, Arial;
				font-size: 10px;
			}

			.h {
				fill: #f19305;
			}

			.i {
				mix-blend-mode: screen;
				opacity: .5;
			}
		</style>
	</defs>
	<g class="f">
		<g id="a" />
		<g id="b">
			<g>
				<g>
					<g>
						<polygon class="e" points="115 112.01 109.34 117.66 109.32 106.3 115 112.01" />
						<polygon class="e" points="7.18 5 1.52 10.57 12.79 10.57 7.18 5" />
					</g>
					<g>
						<polygon class="e" points="63.69 5 74.38 10.22 68.88 10.22 63.69 5" />
						<polygon class="e" points="115 56.64 109.64 45.75 109.6 51.28 115 56.64" />
					</g>
					<polygon class="h" points="115 112 7.18 5 63.71 5.01 115 56.63 115 112" /><text class="g" transform="translate(54 28) rotate(45)">
						<tspan x="0" y="0">{{getFileType()}}</tspan>
					</text>
				</g>
				<g class="i">
					<rect class="d" x="57.26" y="4.32" width=".8" height="2" transform="translate(13.12 42.33) rotate(-45)" />
					<path class="d" d="M110.58,58.81l-2.92-2.92,.57-.57,2.92,2.92-.57,.57Zm-5.83-5.83l-2.92-2.92,.57-.57,2.92,2.92-.57,.57Zm-5.83-5.83l-2.92-2.92,.57-.57,2.92,2.92-.57,.57Zm-5.83-5.83l-2.92-2.92,.57-.57,2.92,2.92-.57,.57Zm-5.83-5.83l-2.92-2.92,.57-.57,2.92,2.92-.57,.57Zm-5.83-5.83l-2.92-2.92,.57-.57,2.92,2.92-.57,.57Zm-5.83-5.83l-2.92-2.92,.57-.57,2.92,2.92-.57,.57Zm-5.83-5.83l-2.92-2.92,.57-.57,2.92,2.92-.57,.57Zm-5.83-5.83l-2.92-2.92,.57-.57,2.92,2.92-.57,.57Z" />
					<rect class="d" x="114.09" y="61.15" width=".8" height="2" transform="translate(-10.43 99.12) rotate(-44.98)" />
					<rect class="d" x="14.08" y="4.32" width=".8" height="2" transform="translate(.48 11.8) rotate(-45)" />
					<path class="d" d="M110.77,102.18l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Zm-5.64-5.64l-2.82-2.82,.57-.57,2.82,2.82-.57,.57Z" />
					<rect class="d" x="114.18" y="104.43" width=".8" height="2" transform="translate(-40.99 111.9) rotate(-45)" />
				</g>
			</g>
		</g>
		<g id="c" />
	</g>
</svg> -->
