import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {BackendService} from '../backend/backend.service';
import {NrDropdownItem} from './nrdropdownitem.class';
import {IAutocompleteRequest} from '../backend/naar-api-client';
import {BaseDataService} from '../basedata/basedata.service';

export class NrDropdownSource {

	public query$: Subject<string> = new Subject<string>();
	public searching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public items$: BehaviorSubject<NrDropdownItem[]> = new BehaviorSubject<NrDropdownItem[]>([]);

	public foreingKeyName: string = null;
	public foreingKeyValue: string = null;
	public nameFormat: string;
	
	private _limit = 500;
	private _currentPage = 1;
	private _currentTerm = null;

	constructor(
		private tableName: string,
		private backend: BackendService,
		private baseData: BaseDataService,
        private suggestedItems: NrDropdownItem[] = null) {
		this.query$.pipe(
			tap(() => this.searching$.next(true)),
			debounceTime(200),
			distinctUntilChanged(),
			switchMap((term) => this._search(term)),
			delay(0),
			tap(() => this.searching$.next(false))
		).subscribe(values => {
            if ((values == null || values.length === 0) && suggestedItems != null) {
                values = [...suggestedItems];
            }
            this.items$.next(values);
		}, err => {
			console.error(err);
		});
        
        if (suggestedItems) {
            this.items$.next(suggestedItems);
        }
	}

	public more() {
		this._currentPage++;
		const req: IAutocompleteRequest = {
			siteID: this.baseData.siteID,
			tableName: this.tableName,
			term: this._currentTerm,
			limit: this._limit,
			pageNr: this._currentPage,
			lingua: this.baseData.getLingua(),
			fieldName: this.foreingKeyName,
			nameFormat: this.nameFormat,
			foreingKeyValue: this.foreingKeyValue
		};
		this.searching$.next(true);
		this.backend.call('autocomplete/search', req).subscribe(data => {
			if (data.values) {
				const mapped = data.values.map(value => new NrDropdownItem(
					value.id,
					value.label,
					value.extraInt,
					value.extraString,
					value.extraString2,
					value.extraDecimal,
					value.extraBool,
					value.inactive,
					value.deleted,
					value.qualifier));
				this.items$.next(mapped);
			}
			this.searching$.next(false);
		}, err => {
			console.error(err);
		});
	}

	private _search(term: string): Observable<NrDropdownItem[]> {
		this._currentPage = 1;
		this._currentTerm = term;
		const req: IAutocompleteRequest = {
			siteID: this.baseData.siteID,
			tableName: this.tableName,
			term,
			limit: this._limit,
			pageNr: 1,
			lingua: this.baseData.getLingua(),
			fieldName: this.foreingKeyName,
			nameFormat: this.nameFormat,
			foreingKeyValue: this.foreingKeyValue
		};

		return this.backend
			.call('autocomplete/search', req)
			.pipe(map(data => {
				if (data.values) {
					const mapped = data.values.map(value => new NrDropdownItem(
						value.id,
						value.label,
						value.extraInt,
						value.extraString,
						value.extraString2,
						value.extraDecimal,
						value.extraBool,
						value.inactive,
						value.deleted,
						value.qualifier));
					return mapped;
				} else {
					return [];
				}
			}));
	}

	public getItem(id: number): Observable<NrDropdownItem> {
		if (!id) {
			this.items$.next([]);
			return of(null);
		}
		const req: IAutocompleteRequest = {
			id: String(id),
			tableName: this.tableName,
			lingua: this.baseData.getLingua(),
			fieldName: this.foreingKeyName,
			nameFormat: this.nameFormat,
			foreingKeyValue: this.foreingKeyValue
		};
		return this.backend
			.call('autocomplete/value', req)
			.pipe(map(data => {
				const item = data.value && data.value.id ? new NrDropdownItem(
					data.value.id,
					data.value.label,
					data.value.extraInt,
					data.value.extraString,
					data.value.extraString2,
					data.value.extraDecimal,
					data.value.extraBool,
					data.value.inactive,
					data.value.deleted,
					data.value.qualifier) : null;
				const values = item ? [item] : [];
				this.items$.next(values);
				return item;
			}));
	}
}
