import {DocLang, IHelpDocument} from './help-document.interface';

export interface IHelpNode {
	codice: string;
	titolo: { [lang in DocLang]?: string };
	documento?: IHelpDocument;
	documenti?: IHelpDocument[];
	nodi?: IHelpNode[];
}

export interface IHelpDocumentsIndex {
	documenti: IHelpDocument[];
	struttura: IHelpNode[];
}
