import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	Mappa,
	TripItineraryDay
} from 'nr-common';

@Component({
	selector: 'nr-mappa',
	templateUrl: './mappa.component.html',
	styleUrls: ['./mappa.component.scss']
})
export class MappaComponent implements OnInit {
	@Input() mappa: Mappa;
	@Input() tappe: TripItineraryDay[];	// ServiceItineraryStep[];

	constructor() { }

	ngOnInit(): void {
	}

	/**
	 * Imposta il titolo della tappa sulla mappa
	 * @param i tappa sulla mappa
	 * @returns string
	 */
	getTitle(i: TripItineraryDay): string {
		return `${i.ordine} - ${i.luogo}`;
	}

	onMapReady(map?: google.maps.Map) {
		if (map) {
			map.setOptions({
				streetViewControl: false
			});
		}
	}

}
