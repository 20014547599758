import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'projects/test-apps/nr-maps-test/src/environments/environment';
import { AerialMetadataFormatEnum, AerialViewGetVideoResponse, AerialViewStateEnum } from '../../models/aerial-view.model';

export type AerialViewVideoSourceType = { videoId: string, videoLink: string, videoPoster: string };

@Injectable()
export class AerialViewManagerService {
    
    private _aerialViewsByAddress: Map<string, AerialViewVideoSourceType> = new Map<string, AerialViewVideoSourceType>();
    private _aerialViewsById: Map<string, AerialViewVideoSourceType> = new Map<string, AerialViewVideoSourceType>();

    public get hasAerialViews(): boolean {
        return this._aerialViewsByAddress.size > 0 || this._aerialViewsById.size > 0;
    }

    private API_ADDRESS = "https://aerialview.googleapis.com/v1beta";

    constructor(private httpClient: HttpClient) { }

    /**
     * Check if the aerial view exists and return the video link, if any
     * @param address 
     * https://developers.google.com/maps/documentation/aerial-view/reference/rest/v1beta/videos/getVideo
     */
    public getAeriaView(address: string, videoFormat: AerialMetadataFormatEnum, videoId: string = undefined): Promise<AerialViewVideoSourceType> {

        return new Promise<AerialViewVideoSourceType>((resolve, reject) => {
            
            if (this._aerialViewsByAddress.has(address)) {
                resolve(this._aerialViewsByAddress.get(address));
                return;
            }
            if (videoId != null && this._aerialViewsById.has(videoId)) {
                resolve(this._aerialViewsById.get(videoId));
                return;
            }

            let httpAddress = `${this.API_ADDRESS}/videos?key=${environment.googleMapsApiKey}`;
            if (videoId != null) {
                httpAddress += `&videoId=${videoId}`;
            } else {
                httpAddress += `&address=${address}`;
            }

            return this.httpClient.get<AerialViewGetVideoResponse>(httpAddress).subscribe( {
                next: data => {
                    if (data.state != AerialViewStateEnum.Active) {
                        reject("The video is not ready!");
                        return;
                    }
                    const videoSource: AerialViewVideoSourceType = {
                        videoId: data.metadata.videoId,
                        videoLink: data.uris[videoFormat].landscapeUri,
                        videoPoster: data.uris[AerialMetadataFormatEnum.Image].landscapeUri
                    };

                    this._aerialViewsByAddress.set(address, videoSource);

                    resolve(videoSource);
                },
                error: error => {
                    if(error.status === 404) {
                        this.renderNewVideo(address).then(
                            responce => { return },
                            error => console.error(error.error.error.message)
                        );
                        reject("The video is not ready!");
                        return;
                    }
                    reject(error);
                }
            });
        });
    }

    public renderNewVideo(address: string): Promise<any> {
        let httpAddress = `${this.API_ADDRESS}/videos:renderVideo?key=${environment.googleMapsApiKey}&address=${address}`;

        return new Promise<any>((resolve, reject) => {
            return this.httpClient.post<any>(httpAddress, undefined).subscribe({
                next: data => {
                    resolve(data);
                },
                error: error => {
                    reject(error);
                }
            });
        });
    }
}
