import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
	selector: '[nr-max-value][ngModel]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: NrMaxValueDirective,
			multi: true
		}
	]
})

export class NrMaxValueDirective implements Validator {

	// tslint:disable-next-line:no-input-rename
	@Input('nr-max-value') maxValue: number;

	registerOnValidatorChange(fn: () => void): void {
	}

	validate(control: AbstractControl): ValidationErrors | null {
		if (isNaN(control.value)) {
			return null;
		}
		if (parseInt(control.value, 10) > this.maxValue) {
			return {maxValue: true};
		}
		return null;
	}
}
