import {
	Component,
	OnInit
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { WebsiteService } from '../../services/website.service';
import { BasePageComponent } from '../../shared/components/base-page/base-page.component';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nr-offerspage',
  templateUrl: './offerspage.component.html',
  styleUrls: ['./offerspage.component.scss']
})
export class OfferspageComponent extends BasePageComponent implements OnInit {

	constructor(
		apiService: ApiService,
		webSiteService: WebsiteService,
		titleService: Title,
		translateService: TranslateService
	) {
		super(apiService, webSiteService, titleService, translateService);
		this.pageName = 'offers';
		// this.pageLinkId = this.webSiteService.CMS_PAGELINK_ID['offers'];	// 35 è il CMS-Id della pagina Idee del NAAR-B2c
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

}
