import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from '../../../services/url.service';
import { TripCard } from '../../models/trip-card';

@Component({
	selector: 'nr-trip-card',
	templateUrl: './trip-card.component.html',
	styleUrls: ['./trip-card.component.scss']
})
export class TripCardComponent implements OnInit {
	@Input() trip: TripCard

	constructor(
		public translate: TranslateService,
		public urlService: UrlService
	) { }

	ngOnInit(): void {
	}

}
