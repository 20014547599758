import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { GoogleMapsAPIWrapper } from '../services';


/**
 * @deprecated The directive should not be used, use 'nr-maps-route' instead.
 */
@Directive({
    selector: 'nr-maps nr-maps-direction'
})
export class NrMapsDirection {  
    @Input() origin: string | google.maps.Place | google.maps.LatLng | google.maps.LatLngLiteral;
    @Input() destination: string | google.maps.Place | google.maps.LatLng | google.maps.LatLngLiteral;
    @Input() travelMode?: google.maps.TravelMode;
    @Input() transitOptions?: google.maps.TransitOptions;
    @Input() drivingOptions?: google.maps.DrivingOptions;
    @Input() waypoints: google.maps.DirectionsWaypoint[] = [];
    @Input() optimizeWaypoints = true;
    @Input() provideRouteAlternatives = false;
    @Input() avoidHighways = false;
    @Input() avoidTolls = false;
    @Input() avoidFerries = false;
    @Input() unitSystem?: google.maps.UnitSystem;
    @Input() renderOptions?: google.maps.DirectionsRendererOptions;
    @Input() markerOptions: {
        origin: google.maps.marker.AdvancedMarkerElementOptions,
        destination: google.maps.marker.AdvancedMarkerElementOptions,
        waypoints?: google.maps.marker.AdvancedMarkerElementOptions,
    };
    @Input() infoWindow: google.maps.InfoWindow;
    // Remove or draw direction
    @Input() visible = true;
    // Render exist direction
    @Input() renderRoute?: google.maps.DirectionsResult | null;
    // Direction change event handler
    @Output() onChange: EventEmitter<google.maps.DirectionsResult> = new EventEmitter<google.maps.DirectionsResult>();
    // Direction response for the new request
    @Output() onResponse: EventEmitter<google.maps.DirectionsResult> = new EventEmitter<google.maps.DirectionsResult>();
    // Send a custom infowindow
    @Output() sendInfoWindow: EventEmitter<google.maps.InfoWindow> = new EventEmitter<google.maps.InfoWindow>();
    // Status of Directions Query (google.maps.DirectionsStatus.OVER_QUERY_LIMIT)
    @Output() status: EventEmitter<google.maps.DirectionsStatus> = new EventEmitter<google.maps.DirectionsStatus>();
    // Marker drag event handler
    @Output() originDrag: EventEmitter<any> = new EventEmitter<any>();
    @Output() destinationDrag: EventEmitter<any> = new EventEmitter<any>();
    
    private directionsService: google.maps.DirectionsService;
    private directionsRenderer: google.maps.DirectionsRenderer;
    // Use for custom marker
    private originMarker?: any;
    private destinationMarker?: any;
    private waypointsMarker: Array<google.maps.marker.AdvancedMarkerElement> = [];
    // Use for visible flag
    private isFirstChange = true;

    private readonly MAX_WAYPOINTS = 25;

    constructor(
        private gmapsApi: GoogleMapsAPIWrapper,
    ) { }
    ngOnInit() {
        if (this.visible === true) {
            this.directionDraw();
        }
    }
    ngOnChanges(obj: any) {
        /**
         * When visible is false then remove the direction layer
         */
        if (!this.visible) {
            try {
                this.removeMarkers();
                this.removeDirections();
            } catch (e) { }
        } else {
            if (this.isFirstChange) {
                /**
                 * When visible is false at the first time
                 */
                if (typeof this.directionsRenderer === 'undefined') {
                    this.directionDraw();
                }
                this.isFirstChange = false;
                return;
            }
            /**
             * When renderOptions are not first change then reset the display
             */
            if (typeof obj.renderOptions !== 'undefined') {
                if (obj.renderOptions.firstChange === false) {
                    this.removeMarkers();
                    this.removeDirections();
                }
            }
            this.directionDraw();
        }
    }
    ngOnDestroy() {
        this.destroyMarkers();
        this.removeDirections();
    }
    /**
   * This event is fired when the user creating or updating this direction
   */
    private directionDraw() {
        this.gmapsApi.getNativeMap().then(map => {
            if (typeof this.directionsRenderer === 'undefined') {
                this.directionsRenderer = new google.maps.DirectionsRenderer(this.renderOptions);
                // @ts-ignore
                this.directionsRenderer.setMap(map);
                this.directionsRenderer.addListener('directions_changed', () => {
                    this.onChange.emit(this.directionsRenderer.getDirections());
                });
            }
            if (typeof this.directionsService === 'undefined') {
                this.directionsService = new google.maps.DirectionsService();
            }
            this.directionsRenderer.setPanel(null);
            // Render exist direction
            if (this.renderRoute) {
                this.directionsRenderer.setDirections(this.renderRoute);
                this.renderRoute = undefined;
            } else {

                // OM 18.10.2023: max waypoints workaround
                if (this.waypoints != null && this.waypoints.length >= this.MAX_WAYPOINTS) {
                    this.waypoints = [...this.waypoints.slice(0, this.MAX_WAYPOINTS - 1), this.waypoints[this.waypoints.length - 1]];
                    //console.warn("Max waypoints exceed. New waypoints list:", this.waypoints)
                }

                // Request new direction
                this.directionsService.route({
                    origin: this.origin,
                    destination: this.destination,
                    travelMode: this.travelMode || google.maps.TravelMode.DRIVING,
                    transitOptions: this.transitOptions,
                    drivingOptions: this.drivingOptions,
                    waypoints: this.waypoints,
                    optimizeWaypoints: this.optimizeWaypoints,
                    provideRouteAlternatives: this.provideRouteAlternatives,
                    avoidHighways: this.avoidHighways,
                    avoidTolls: this.avoidTolls,
                    avoidFerries: this.avoidFerries,
                    unitSystem: this.unitSystem,
                }, (response, status) => {
                    this.onResponse.emit(response);
                    // Emit Query Status
                    this.status.emit(status);
                    /**
                     * DirectionsStatus
                     * https://developers.google.com/maps/documentation/javascript/directions#DirectionsStatus
                     */
                    switch (status) {
                        case google.maps.DirectionsStatus.OK:
                            this.directionsRenderer.setDirections(response);
                            /**
                             * Emit The DirectionsResult Object
                             * https://developers.google.com/maps/documentation/javascript/directions?hl=en#DirectionsResults
                             */
                            // Custom Markers
                            if (typeof this.markerOptions !== 'undefined') {
                                this.destroyMarkers();
                                // Set custom markers
                                const _route = response.routes[0].legs[0];
                                try {
                                    // Origin Marker
                                    if (typeof this.markerOptions.origin !== 'undefined') {
                                        this.markerOptions.origin.map = map;
                                        this.markerOptions.origin.position = _route.start_location;
                                        this.originMarker = this.setMarker(
                                            map,
                                            this.originMarker,
                                            this.markerOptions.origin,
                                            _route.start_address,
                                        );
                                        if (this.markerOptions.origin.gmpDraggable) {
                                            this.originMarker.addListener('dragend', () => {
                                                this.origin = this.originMarker.position;
                                                this.directionDraw();
                                                this.originDrag.emit(this.origin);
                                            });
                                        }
                                    }
                                    // Destination Marker
                                    if (typeof this.markerOptions.destination !== 'undefined') {
                                        this.markerOptions.destination.map = map;
                                        this.markerOptions.destination.position = _route.end_location;
                                        this.destinationMarker = this.setMarker(
                                            map,
                                            this.destinationMarker,
                                            this.markerOptions.destination,
                                            _route.end_address,
                                        );
                                        if (this.markerOptions.destination.gmpDraggable) {
                                            this.destinationMarker.addListener('dragend', () => {
                                                this.destination = this.destinationMarker.position;
                                                this.directionDraw();
                                                this.destinationDrag.emit(this.destination);
                                            });
                                        }
                                    }
                                    // Waypoints Marker
                                    if (typeof this.markerOptions.waypoints !== 'undefined') {
                                        this.waypoints.forEach((waypoint, index) => {
                                            // If waypoints are not array then set all the same
                                            if (!Array.isArray(this.markerOptions.waypoints)) {
                                                this.markerOptions.waypoints!.map = map;
                                                this.markerOptions.waypoints!.position = _route.via_waypoints[index];
                                                this.waypointsMarker.push(this.setMarker(
                                                    map,
                                                    waypoint,
                                                    this.markerOptions.waypoints,
                                                    _route.via_waypoints[index],
                                                ));
                                            } else {
                                                this.markerOptions.waypoints[index].map = map;
                                                this.markerOptions.waypoints[index].position = _route.via_waypoints[index];
                                                this.waypointsMarker.push(this.setMarker(
                                                    map,
                                                    waypoint,
                                                    this.markerOptions.waypoints[index],
                                                    _route.via_waypoints[index],
                                                ));
                                            }
                                        }); // End forEach
                                    }
                                } catch (err) {
                                    console.error('MarkerOptions error.', err);
                                }
                            }
                            break;
                        case google.maps.DirectionsStatus.OVER_QUERY_LIMIT:
                            console.warn('The webpage has sent too many requests within the allowed time period.');
                            break;
                        default:
                            // console.warn(status);
                            break;
                    } // End switch
                });
            }
        });
    }
    /**
     * Custom Origin and Destination Icon
     * @param map map
     * @param marker marker
     * @param markerOpts properties
     * @param content marker's infowindow content
     * @returns new marker
     * @memberof AgmDirection
     */
    private setMarker(
        map: google.maps.Map,
        marker: google.maps.marker.AdvancedMarkerElement | google.maps.DirectionsWaypoint,
        markerOpts: any,
        content: google.maps.LatLng | string
    ): google.maps.marker.AdvancedMarkerElement {
        if (typeof this.infoWindow === 'undefined') {
            this.infoWindow = new google.maps.InfoWindow();
            this.sendInfoWindow.emit(this.infoWindow);
        }
        marker = new google.maps.marker.AdvancedMarkerElement(markerOpts);
        // https://developers.google.com/maps/documentation/javascript/reference/marker?hl=zh-tw#MarkerOptions.clickable
        if (marker.gmpClickable) {
            marker.addListener('click', () => {
                const infowindoContent: string = typeof markerOpts.infoWindow === 'undefined' ? content : markerOpts.infoWindow;
                this.infoWindow.setContent(infowindoContent);
                this.infoWindow.open(map, marker as google.maps.MVCObject);
            });
        }
        return marker;
    }
    /**
     * This event is fired when remove markers
     */
    private removeMarkers(): void {
        if (typeof this.originMarker !== 'undefined') {
            this.originMarker.setMap(null);
        }
        if (typeof this.destinationMarker !== 'undefined') {
            this.destinationMarker.setMap(null);
        }
        this.waypointsMarker.forEach((w: any) => {
            if (typeof w !== 'undefined') {
                w.setMap(null);
            }
        });
    }
    /**
     * This event is fired when remove directions
     */
    private removeDirections(): void {
        if (this.directionsRenderer !== undefined) {
            // @ts-ignore
            this.directionsRenderer.setPanel(null);
            this.directionsRenderer.setMap(null);
            // @ts-ignore
            this.directionsRenderer = undefined;
        }
    }
    /**
     * This event is fired when destroy markers
     */
    private destroyMarkers(): void {
        // Remove origin markers
        try {
            if (typeof this.originMarker !== 'undefined') {
                google.maps.event.clearListeners(this.originMarker, 'click');
                if (this.markerOptions.origin.gmpDraggable) {
                    google.maps.event.clearListeners(this.originMarker, 'dragend');
                }
            }
            if (typeof this.destinationMarker !== 'undefined') {
                google.maps.event.clearListeners(this.destinationMarker, 'click');
                if (this.markerOptions.origin.gmpDraggable) {
                    google.maps.event.clearListeners(this.destinationMarker, 'dragend');
                }
            }
            this.waypointsMarker.forEach((w: any) => {
                if (typeof w !== 'undefined') {
                    google.maps.event.clearListeners(w, 'click');
                }
            });
            this.removeMarkers();
        } catch (err) {
            console.error('Can not reset custom marker.', err);
        }
    }
}