import { CommonModule }              from "@angular/common";
import { NgModule }                  from "@angular/core";
import { RouterModule }              from "@angular/router";
import {
	FormsModule,
	ReactiveFormsModule
} from "@angular/forms";
import { TranslateModule }           from "@ngx-translate/core";
import { SlickCarouselModule }       from 'ngx-slick-carousel';
import { AppRoutingModule }          from "../app-routing.module";
import { SharedModule }              from "../shared/shared.module";
import { AboutpageComponent }        from "./aboutpage/aboutpage.component";
// import { ContactspageComponent }     from "./contactspage/contactspage.component";
import { DestinationpageComponent }  from "./destinationpage/destinationpage.component";
import { DestinationspageComponent } from "./destinationspage/destinationspage.component";
import { FooterComponent }           from "./footer/footer.component";
import { HomepageComponent }         from "./homepage/homepage.component";
import { IdeaspageComponent }        from "./ideaspage/ideaspage.component";
import { NavBarComponent }           from "./nav-bar/nav-bar.component";
import { OfferspageComponent }       from "./offerspage/offerspage.component";
import { PartnerspageComponent }     from "./partnerspage/partnerspage.component";
import { IdeapageComponent }         from './ideapage/ideapage.component';
import { TrippageComponent }         from './trippage/trippage.component';
import { TappeComponent }            from './trippage/tappe/tappe.component';
import { MappaComponent }            from './trippage/mappa/mappa.component';
import { AttributiComponent }        from './trippage/attributi/attributi.component';
// import { ContactListComponent }      from './contactspage/contact-list/contact-list.component';
// import { ContactFormComponent }      from "./contactspage/contact-form/contact-form.component";
import {
	NgbCarouselModule,
	NgbDropdownModule,
	NgbModalModule,
	NgbModule
} from "@ng-bootstrap/ng-bootstrap";
import { ArticlepageComponent } from './articlepage/articlepage.component';
import { OpendestpageComponent } from './opendestpage/opendestpage.component';
import { OpendestCardComponent } from './opendest-card/opendest-card.component';
import { LoginComponent } from './login/login.component';
import { OpendestpageDetailComponent } from './opendestpage-detail/opendestpage-detail.component';
import { NrCommonModule } from 'nr-common';
import { NrMapsModule } from "nr-maps";

@NgModule({
	imports: [
		SharedModule,
		AppRoutingModule,
		CommonModule,
		RouterModule,
		TranslateModule,
		SlickCarouselModule,
		FormsModule,
		ReactiveFormsModule,
        NrMapsModule,
		NgbCarouselModule,
		NgbDropdownModule,
		NgbModalModule,
		NgbModule,
		NrCommonModule
	],
	declarations: [
		HomepageComponent,
		NavBarComponent,
		FooterComponent,
		DestinationpageComponent,
		DestinationspageComponent,
		IdeaspageComponent,
		OfferspageComponent,
		// ContactspageComponent,
		PartnerspageComponent,
		AboutpageComponent,
		IdeapageComponent,
		TrippageComponent,
		TappeComponent,
		MappaComponent,
		AttributiComponent,
		ArticlepageComponent,
		OpendestpageComponent,
		OpendestCardComponent,
		LoginComponent,
  OpendestpageDetailComponent,
		// ContactListComponent,
		// ContactFormComponent
	],
	exports: [
		HomepageComponent,
		NavBarComponent,
		FooterComponent,
		DestinationpageComponent,
		DestinationspageComponent,
		IdeaspageComponent,
		OfferspageComponent,
		// ContactspageComponent,
		PartnerspageComponent,
		AboutpageComponent,
		IdeapageComponent,
		TrippageComponent,
		TranslateModule
	],
	entryComponents: [LoginComponent]
})
export class ComponentsModule { }
