export { NrMaps } from "./directives/nr-maps";
export { NrMarker } from "./directives/nr-marker";
export { NrInfoWindow } from "./directives/nr-info-window";
export { NrPolyline } from "./directives/nr-polyline";
export { NrPolylinePoint } from "./directives/nr-polyline-point";
export { NrPolylineIcon } from "./directives/nr-polyline-icon";
export { NrPolygon } from "./directives/nr-polygon";
export { NrCircle } from "./directives/nr-circle";
export { NrRectangle } from "./directives/nr-rectangle";
export { NrMapsDirection } from "./directives/nr-directions";
export { NrMapsRoute } from "./directives/nr-route";
export { NrAerialView, NrAerialQualitiEnum } from "./directives/nr-aerial-view";
export { NrMapControl, NrFullscreenControl, NrMapTypeControl, NrPanControl, NrRotateControl, NrScaleControl, NrStreetViewControl, NrZoomControl } from "./directives/nr-map-controls";

export { NrFitBounds } from "./directives/nr-fit-bounds";