import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {NrSelectableItem} from './nrselectableitem.class';
import {debounceTime, delay, switchMap, tap} from 'rxjs/operators';

export class NrMultiSelect<T> {

	private _itemId = 0;
	private _items: NrSelectableItem<T>[] = [];
	private _changeSelection = new Subject<void>();

	public changing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public selected: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
	public allSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public selectOrder = 0;

	constructor() {
		this._changeSelection.pipe(
			tap(() => this.changing.next(true)),
			debounceTime(200),
			switchMap(() => this._querySelection()),
			delay(0),
			tap(() => this.changing.next(false))
		).subscribe((selected) => {
			this.allSelected.next(this._items.length && selected.length === this._items.length);
			this.selected.next(selected);
		});
	}

	public addItem(item: T): NrSelectableItem<T> {
		const selectableItem = new NrSelectableItem<T>(this._itemId, item);
		this._itemId++;
		this._items.push(selectableItem);
		this._changeSelection.next();
		return selectableItem;
	}

	public removeItem(itemToRemove: NrSelectableItem<T>) {
		const id = this._items.findIndex(item => item.id === itemToRemove.id);
		if (id >= 0) {
			this._items.splice(id, 1);
			this._changeSelection.next();
		}
	}

	public itemSelectionChanged() {
		this._changeSelection.next();
	}

	public changeAllSelected(allSelected: boolean) {
		this._items.forEach(item => item.selected = allSelected);
		this._changeSelection.next();
	}

	private _querySelection(): Observable<T[]> {
		const selected = this._items
			.filter(item => item.selected)
			.sort((a, b) => a.selectOrder === b.selectOrder ? 0 : a.selectOrder > b.selectOrder ? 1 : -1)
			.map(item => item.item);
		return of(selected);
	}
}
